import React, { useState, useEffect } from "react";

import { useSpring, animated } from "react-spring";

export default function Hero() {
  const AnimFeTurbulence = animated("feTurbulence");
  const AnimFeDisplacementMap = animated("feDisplacementMap");
  const [open, toggle] = useState(true);
  const [other, nother] = useState(true);

  useEffect(() => {
    async function fetch() {
      setTimeout(() => {
        toggle(false);
      }, 4000);
      setTimeout(() => {
        nother(false);
      }, 4000);
    }
    fetch();
  }, []);
  const { freq, scale } = useSpring({
    reverse: open,
    from: { scale: 150, transform: "scale(1)", freq: "0.0, 0.0" },

    to: { scale: 10, transform: "scale(0.0)", freq: "0.0175, 0.0" },
    config: { duration: 0 },
  });
  const { opacity } = useSpring({
    reverse: other,
    from: { opacity: 0 },
    to: { opacity: 0 },
    config: { duration: 0 },
  });
  /* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 421 201"
          id="green-svg"
          fill="#24323B"
        >
          <mask id="path-1-inside-1_427_281" fill="white">
            <path d="M120 90C120 78.181 117.672 66.4778 113.149 55.5585C108.626 44.6392 101.997 34.7177 93.6396 26.3604C85.2823 18.0031 75.3608 11.3738 64.4415 6.85084C53.5222 2.32792 41.819 -5.16623e-07 30 0C18.181 5.16624e-07 6.47779 2.32792 -4.44151 6.85084C-15.3608 11.3738 -25.2823 18.0031 -33.6396 26.3604C-41.9969 34.7177 -48.6262 44.6392 -53.1492 55.5585C-57.6721 66.4778 -60 78.181 -60 90L30 90H120Z" />
          </mask>
          <path
            d="M120 90C120 78.181 117.672 66.4778 113.149 55.5585C108.626 44.6392 101.997 34.7177 93.6396 26.3604C85.2823 18.0031 75.3608 11.3738 64.4415 6.85084C53.5222 2.32792 41.819 -5.16623e-07 30 0C18.181 5.16624e-07 6.47779 2.32792 -4.44151 6.85084C-15.3608 11.3738 -25.2823 18.0031 -33.6396 26.3604C-41.9969 34.7177 -48.6262 44.6392 -53.1492 55.5585C-57.6721 66.4778 -60 78.181 -60 90L30 90H120Z"
            stroke="#88BC76"
            stroke-width="4"
            mask="url(#path-1-inside-1_427_281)"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 195 142"
          id="yellow-icon"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="orange">
                <path d="M95.4667 18H18V42.7333H95.4667V18Z" fill="#FEB734" />{" "}
              </g>
              <g id="blue">
                <path
                  d="M63.8524 1L1 63.8524V1H63.8524Z"
                  fill="#24323B"
                  stroke="#2E8DD1"
                  stroke-width="2"
                />{" "}
              </g>
            </g>
          </g>
        </svg>
"*/

  return (
    <section className="hero has-background-success">
      <div style={{ opacity }} className="hero-body">
        <div className="container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="red-ball"
            viewBox="0 0 20.6 20.6"
            fill="#24323B"
          >
            <path
              d="M18.89 9.94501C18.89 14.8852 14.8852 18.89 9.94502 18.89C5.00482 18.89 1 14.8852 1 9.94501C1 5.00482 5.00482 1 9.94502 1C14.8852 1 18.89 5.00482 18.89 9.94501Z"
              stroke="#88BC76"
              stroke-width="2"
            />
          </svg>
          <div className=" text-box margin-top-10">
            <div className="body-text white type-4 event-date">
              <p></p>
            </div>
            <div className="text-size-large heading-text white spacetwo">
              <p className="has-text-link">Fin42</p>
              <p className="">postponed till</p>
              <p className="has-text-link">Further notice</p>
            </div>
            <div className="body-text white type-4 event-date">
              <p>
                We hope that next year will be the year where we can all gather
                in Iceland for the latest and greatest in Fintech
              </p>
            </div>
            <div className="body-text white type-4 event-date"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
