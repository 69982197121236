import React from "react";

export default function Card({ path, img, title, type }) {
  const titlejob = type.split(",");
  return (
    <div className="column is-one-third">
      <a href={path + "#" + title.split(" ")[0]}>
        <div className="card">
          <figure className="image is-4by4">
            <img alt="Profile" loading="lazy" src={img}></img>
          </figure>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <div className="heading-text type-5">
                  <p className="">{title}</p>
                </div>
                <div className="body-text type-2">
                  <p>{titlejob[0]}</p>
                  <p>{titlejob[1]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
