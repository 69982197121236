import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Nav from "../Elements/Nav";
import Home from "../Home/index";
import Footer from "../Elements/Footer";
import Speakers from "../Speakers/index";
import Location from "../Location/index";
import Agenda from "../Agenda/index";
import Agenda2 from "../Agenda/Content2";
import Events from "../Events/index";

export default function App() {
  return (
    <Router>
      <div className="has-navbar-fixed-top">
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/agenda" component={Agenda} />
          <Route path="/agenda2" component={Agenda2} />
          <Route path="/speakers" component={Speakers} />
          <Route path="/location" component={Location} />
          <Route path="/events" component={Events} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
