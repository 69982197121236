import React from "react";

const SVG = () => (
  <svg
    width="140"
    height="83"
    viewBox="-25 -10 180 43"
    fill="none"
    id="fin42logo"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.6259 29.5951C72.6259 22.4172 66.81 16.6012 59.6321 16.6012C52.4541 16.6012 46.6382 22.4172 46.6382 29.5951"
      fill="#88BC76"
    />
    <path d="M0 16.5276V42.589L26.0982 16.5276H0Z" fill="#88BC76" />
    <path d="M13.0307 0H0V42.589H13.0307V0Z" fill="#727DC0" />
    <path d="M0 0V26.0982L26.0982 0H0Z" fill="#37407B" />
    <path d="M42.81 16.5276H29.7793V42.6258H42.81V16.5276Z" fill="#727DC0" />
    <path
      d="M138.994 14.7975C138.994 6.62577 132.368 0 124.196 0C116.024 0 109.398 6.62577 109.398 14.7975"
      fill="#88BC76"
    />
    <path
      d="M124.38 29.5583C132.552 29.5583 139.178 22.9325 139.178 14.7607C139.178 6.58896 132.552 0 124.38 0"
      fill="#727DC0"
    />
    <path
      d="M139.325 29.5583H109.436V42.4049H139.325V29.5583Z"
      fill="#37407B"
    />
    <path d="M97.9875 16.454H76.417V29.6319H97.9875V16.454Z" fill="#37407B" />
    <path d="M105.902 0H92.8711V42.589H105.902V0Z" fill="#727DC0" />
    <path d="M92.8344 16.454H76.3804L92.8344 0V16.454Z" fill="#88BC76" />
    <path
      d="M36.2946 13.0307C39.8929 13.0307 42.81 10.1137 42.81 6.51534C42.81 2.91702 39.8929 0 36.2946 0C32.6963 0 29.7793 2.91702 29.7793 6.51534C29.7793 10.1137 32.6963 13.0307 36.2946 13.0307Z"
      fill="#37407B"
    />
    <path d="M29.7793 29.5583L42.81 16.5276H29.7793V29.5583Z" fill="#88BC76" />
    <path d="M72.6259 29.5951H57.7915V42.589H72.6259V29.5951Z" fill="#37407B" />
    <path d="M59.6317 16.5276H46.6011V42.589H59.6317V16.5276Z" fill="#727DC0" />
  </svg>
);
export default SVG;
