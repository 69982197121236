import React from "react";
import ReactPlayer from "react-player";
import Poster from "../static/img/poster.png";
import temp from "../static/img/Fin42mainvideo.mp4";

export default function Hero() {
  //let temp =
  //"http://fin42.meniga.com/wp-content/uploads/2018/06/Fin42mainvideo.mp4";
  return (
    <>
      <div className="hero-1 is-hidden-mobile">
        <div className="video-hero ">
          <ReactPlayer
            url={temp}
            muted
            playing
            width="100%"
            height="100%"
            onProgress={(e) => {
              if (e.playedSeconds > 100) {
                var video = document.getElementsByTagName("video");
                video[0].currentTime = 0;
              }
            }}
          />
        </div>
      </div>
      <div className="is-hidden-desktop">
        <section className="hero">
          <ReactPlayer
            url={temp}
            muted
            controls
            playing
            light={Poster}
            width="100%"
            height="100%"
            className="react-player"
            onProgress={(e) => {
              if (e.playedSeconds > 100) {
                var video = document.getElementsByTagName("video");
                video[0].currentTime = 0;
              }
            }}
          />
        </section>
      </div>
    </>
  );
}
