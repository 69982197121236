import React from "react";

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    id="play"
    viewBox="0 0 26 26"
  >
    <circle cx="13" cy="13" r="12" stroke="#fff" />
    <path
      d="M19.4818 12.7943L9.09998 18.1775V7.79568L19.4818 12.7943Z"
      stroke="#fff"
    />
  </svg>
);

export default SVG;
