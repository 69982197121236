import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import url from "../static/img/Meninga_radstefna_2022_v3.mp4";
import RedBall from "../static/img/RedBall";
import Play from "../static/img/Play";

export default function Hero() {
  const [play, setPlay] = useState();
  const [icon, setIcon] = useState();
  let video = (
    <ReactPlayer
      url={url}
      playing={play}
      onEnded={() => {
        setPlay(!play);
        setIcon(play ? <Play /> : <RedBall />);
      }}
      muted
      height="100%"
      width="100%"
    />
  );
  const handleclick = () => {
    if (video.onEnded) {
      var video2 = document.getElementsByTagName("video");
      video2[0].currentTime = 0;
    }
    setPlay(!play);
    setIcon(play ? <Play /> : <RedBall />);
  };
  useEffect(() => {
    async function fetch() {
      setTimeout(() => {
        setPlay(true);
        setIcon(<RedBall />);
      }, 100);
    }
    fetch();
  }, []);

  return (
    <>
      {window.innerWidth >= 600 ? (
        <section className="hero">
          <div className="hero-body">
            <div className="container has-text-right">
              <div className="heading-text">
                <p>
                  <span className="heading-text-wtih-pimary">This is </span>
                  <span className="has-text-link">how</span>
                </p>
                <p>
                  <span className="has-text-link">we</span>
                  <span className="heading-text-wtih-pimary"> do it!</span>
                </p>
              </div>
              <p className="body-text">
                <b>To be announced -</b> Reykjavik, Iceland
              </p>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}
