import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";

const apiEndpoint = "https://fin42.prismic.io/api/v2";
const accessToken =
  "MC5YbGtCTWhFQUFDQUFuai1T.OXZQ77-9T2Lvv71L77-977-9fwvvv704TAcH77-977-9cu-_ve-_ve-_ve-_vUfvv70Q77-977-977-977-977-9"; // This is where you would add your access token for a Private repository

export const getSpeakers = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "speaker"),
    { orderings: "[my.speaker.highestnumber desc]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.speaker_name),
      res.data.bio ? res.data.bio : "TBA",
      res.data.speakerimage.url ? res.data.speakerimage.url : "TBA",
      RichText.asText(
        res.data.company_postion ? res.data.company_postion : "TBA"
      ),
      RichText.asText(res.data.timeslot ? res.data.timeslot : "TBA"),
      RichText.asText(res.data.typeofspeaker ? res.data.typeofspeaker : "TBA"),
      res.data.companylogo.url ? res.data.companylogo.url : "TBA",
      res.id ? res.id : "",
    ]);
  });

  return result;
};
export const getYTLinks = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "youtube"),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.title),
      res.data.speaker_company
        ? RichText.asText(res.data.speaker_company)
        : "TBA",
      res.data.youtubeid ? RichText.asText(res.data.youtubeid) : "TBA",
    ]);
  });

  return result;
};
export const getTravel = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "travel"),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.title),
      res.data.body ? res.data.body : "TBA",
      res.data.links ? res.data.links : "TBA",
      //res.data.links[0] ? res.data.links[0].link.url : "TBA" //name_of_link
    ]);
  });

  return result;
};
export const getContent = async (property) => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", property),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.title1[0].content),
      RichText.asText(res.data.title1[0].title),
      res.data.title1[0].topimg.url ? res.data.title1[0].topimg.url : "TBA",
      //res.data.links[0] ? res.data.links[0].link.url : "TBA" //name_of_link
    ]);
  });

  return result;
};
export const getContentspeaker = async (property) => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", property),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.title1[0].content),
      RichText.asText(res.data.title1[0].title),
      //res.data.links[0] ? res.data.links[0].link.url : "TBA" //name_of_link
    ]);
  });

  return result;
};
export const getSchedule = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  let results2 = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "agendaitems"),
    { orderings: "[document.data.date]" }
  );
  console.log(response);
  response.results.forEach((res) => {
    const date = RichText.asText(res.data.date);
    const datename = RichText.asText(res.data.datename);
    const items = res.data.items;
    items.forEach((item) => {
      results2.push({
        type: item.typeofevent,
        time: RichText.asText(item.timestart ? item.timestart : "TBH"),
        end: RichText.asText(item.timeends ? item.timeends : "TBH"),
        name: RichText.asText(item.nameofevent),
        speaker: item.speakername,
      });
    });
    result.push([date, results2, datename]);
    console.log(datename);
    results2 = [];
  });

  return result;
};
export const getSchedule2 = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "schedule"),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push([
      RichText.asText(res.data.title),
      RichText.asText(res.data.text),
      res.data.datefield,
    ]);
  });

  return result;
};
export const getLogos = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  let temp = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "logos"),
    { orderings: "[my.logos.highestnumber desc]" }
  );
  response.results.forEach((res) => {
    result.push([res.data.logo.url ? res.data.logo.url : "TBA"]);
  });
  for (let i = 0; i < Math.floor(result.length / 4); i++) {
    temp.push(result.slice(i * 4, (i + 1) * 4));
  }
  return temp;
};

export const getGallery = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];
  let temp = [];
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "eventsgallery"),
    { orderings: "[document.last_publication_date]" }
  );
  response.results.forEach((res) => {
    result.push(res.data.source.url);
  });
  return result;
};

export const getFrontPage = async () => {
  const Client = Prismic.client(apiEndpoint, { accessToken });
  let result = [];

  const response = await Client.query(
    Prismic.Predicates.at("document.type", "frontpage")
  );
  result.push(
    [
      RichText.asText(response.results[0].data.attend_section[0].attend_title),
      RichText.asText(response.results[0].data.attend_section[0].attend),
    ],
    [
      RichText.asText(
        response.results[0].data.location_section[0].location_title
      ),
      RichText.asText(response.results[0].data.location_section[0].location),
    ],
    [
      RichText.asText(
        response.results[0].data.highlights_section[0].highlights_title
      ),
      RichText.asText(
        response.results[0].data.highlights_section[0].highlights
      ),
      RichText.asText(
        response.results[0].data.location_section[0].testimonials
      ),
    ],
    [
      RichText.asText(response.results[0].data.map_section[0].map),
      RichText.asText(response.results[0].data.map_section[0].map_content),
    ]
  );
  return result;
};
