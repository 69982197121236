import React from "react";

const SVG = () => (
  <svg
  viewBox="0 0 708 436"    id="waterfall"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
<path d="M707.498 272.5C707.331 294.78 696.022 316.056 675.589 335.472C655.002 355.034 625.186 372.674 588.302 387.5C514.538 417.149 412.604 435.5 300 435.5C187.396 435.5 85.4624 417.149 11.698 387.5C-25.1856 372.674 -55.0017 355.034 -75.5888 335.472C-96.0219 316.056 -107.331 294.78 -107.498 272.5H707.498Z" fill="#24323B" stroke="#F4F4F0"/>
<path d="M-0.390976 1.42158L-0.390466 1.42216L120.036 138.553C120.036 138.553 120.037 138.554 120.037 138.554C120.975 139.633 120.93 141.281 119.944 142.318C119.943 142.319 119.942 142.32 119.941 142.321L-0.479328 263.678C-0.479546 263.678 -0.479764 263.678 -0.479982 263.679C-2.22405 265.421 -5.18066 264.191 -5.18066 261.755V3.25748C-5.18066 0.716709 -2.02822 -0.448597 -0.390976 1.42158Z" stroke="#F4F4F0"/>
<path d="M350.563 272.077H201.882C172.976 272.077 149.5 246.689 149.5 215.336V51.2916L350.563 272.077Z" fill="#24323B" stroke="#F4F4F0"/>
<path d="M42.2588 49.5609H157.521V292.901H42.2588V49.5609Z" fill="#24323B" stroke="#F4F4F0"/>
<path d="M57.3662 49.5609H62.0321V152.271H57.3662V49.5609Z" fill="white" stroke="#F4F4F0"/>
<path d="M79.9189 90.201H84.5848V192.911H79.9189V90.201Z" fill="white" stroke="#F4F4F0"/>
<path d="M108.305 49.5609H112.971V152.271H108.305V49.5609Z" fill="white" stroke="#F4F4F0"/>
<path d="M132.578 100.361H137.244V203.071H132.578V100.361Z" fill="white" stroke="#F4F4F0"/>
<mask id="path-9-inside-1_329_401" fill="white">
<path d="M204.514 292.624C204.514 235.717 158.353 189.636 101.472 189.636C44.591 189.636 -1.56934 235.717 -1.56934 292.624"/>
</mask>
<path d="M204.514 292.624C204.514 235.717 158.353 189.636 101.472 189.636C44.591 189.636 -1.56934 235.717 -1.56934 292.624" fill="white"/>
<path d="M205.514 292.624C205.514 235.164 158.905 188.636 101.472 188.636V190.636C157.802 190.636 203.514 236.27 203.514 292.624H205.514ZM101.472 188.636C44.0396 188.636 -2.56934 235.164 -2.56934 292.624H-0.569336C-0.569336 236.27 45.1425 190.636 101.472 190.636V188.636Z" fill="#F4F4F0" mask="url(#path-9-inside-1_329_401)"/>
<path d="M42.7022 248.486C42.7022 272.586 23.1549 292.124 -0.958488 292.124C-25.0718 292.124 -44.6191 272.586 -44.6191 248.486C-44.6191 224.386 -25.0718 204.848 -0.958488 204.848C23.1549 204.848 42.7022 224.386 42.7022 248.486Z" fill="white" stroke="#24323B"/>
<path d="M245.841 248.486C245.841 272.586 226.294 292.124 202.18 292.124C178.067 292.124 158.52 272.586 158.52 248.486C158.52 224.386 178.067 204.848 202.18 204.848C226.294 204.848 245.841 224.386 245.841 248.486Z" fill="white" stroke="#24323B"/>
<path d="M26.5 313.833H137.874V318.995H26.5V313.833Z" fill="white" stroke="#F4F4F0"/>
<path d="M120.485 345.146H317.347V350.308H120.485V345.146Z" fill="white" stroke="#F4F4F0"/>
<path d="M212.752 377.014H272.133V382.176H212.752V377.014Z" fill="white" stroke="#F4F4F0"/>
<path d="M402.5 297.559H616.637V302.721H402.5V297.559Z" fill="white" stroke="#F4F4F0"/>
<path d="M550.5 163.5H593.5V296.5H550.5V163.5Z" fill="#88BC76" stroke="#24323B"/>
<path d="M488.5 215.5H530.5V296.5H488.5V215.5Z" fill="#C3DDBB" stroke="#24323B"/>
<path d="M423.5 254.5H465.5V296.5H423.5V254.5Z" fill="white" stroke="#24323B"/>
  </svg>
);
export default SVG;
