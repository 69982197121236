import React, { useState, useEffect } from "react";
import Iceland from "../static/img/iceland";
import Harpa from "../static/img/harpa1.png";
import lance from "../static/img/harpa2.jpg";
import inside from "../static/img/harpa3.jpg";
import ice from "../static/img/harpa4.jpeg";
import Arrow from "../static/img/arrow";
import MailchimpSubscribe from "react-mailchimp-subscribe";

export default function Contact({ content }) {
  const [title, setTitle] = useState(["", ""]);
  const url =
    "https://kasa.us19.list-manage.com/subscribe/post?u=57891df3e3c7dcb324b74087f&id=e990998e7a";

  const [img, setImg] = useState(lance);
  console.log(content);
  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <div className="column is-half bord">
        <div className="heading-text type-1 heading-text-wtih-pimary spacethree">
          <p>Stay Informed</p>
        </div>
        <div className="body-text type-3 spacethree">
          <p>Get conference email updates.</p>
        </div>

        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && <div style={{ color: "red" }}>Error!</div>}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <input
          type="email"
          placeholder="Email here"
          className="input-txt spacesix"
          ref={(node) => (email = node)}
        />
        <div className="heading-text font-1">
          <a onClick={submit} className="button  is-lightblue">
            Subscribe
          </a>
        </div>
      </div>
    );
  };
  return (
    <div className="has-background-primary  section-contact">
      <section className="section-contact ">
        <div className="container ">
          <div className="heading-text type-3 heading-text-wtih-pimary spacethree">
            <p>{content[1][0]}</p>
          </div>
          <div className="body-text spacesix type-3 body-text-with-primary width-65">
            <p>{content[1][1]}</p>

            <div className="arrowLink-2">
              <div className="font-1 body-text-color">
                <a className="black move" href="./location">
                  See more
                  <Arrow />
                </a>
              </div>
            </div>
          </div>

          <div className="columns  imagesharpa spacesix  is-multiline">
            <div className="column is-three-quarters">
              <img alt="harpa-2" className="fullimg " src={img} />
            </div>
            <div className="column  " align="right">
              <img
                alt="harpa-1"
                className={lance === img ? "border-1 is-hidden-mobile" : ""}
                src={lance}
                onClick={() => setImg(lance)}
              />
              <img
                alt="harpa-3"
                id="harpa-3"
                className={Harpa === img ? "border-1 is-hidden-mobile" : ""}
                src={Harpa}
                onClick={() => setImg(Harpa)}
              />
              <img
                alt="harpa-4"
                className={inside === img ? "border-1 is-hidden-mobile" : ""}
                src={inside}
                onClick={() => setImg(inside)}
              />
            </div>
          </div>
          <div className="columns">
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />

            <div className="column is-half is-hidden-mobile">
              <div className="heading-text type-1 heading-text-wtih-pimary spacethree">
                <p>Get in touch</p>
              </div>
              <div className="body-text body-text-with-primary type-3 width-8 spacethree">
                <p>Seats are filling up, get in touch and secure your space</p>
              </div>
              <div className="heading-text font-1 ">
                <a
                  className="button is-lightblue"
                  href="mailto:marketing@meniga.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get in touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Iceland />
    </div>
  );
}
