import React from "react";
import Hero from "./Hero";
import Content from "./Content";

export default function index() {
  return (
    <div className="has-background-success">
      <Hero />
      <Content />
    </div>
  );
}
