import React from "react";

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    id="twitter"
  >
    <circle cx="30" cy="30" r="29.5" stroke="#566B76" />
    <path
      d="M41.4445 23.334C40.6305 23.697 39.9445 23.709 39.2165 23.35C40.1555 22.788 40.1985 22.394 40.5375 21.331C39.6595 21.852 38.6865 22.231 37.6515 22.436C36.8235 21.553 35.6415 21 34.3355 21C31.8255 21 29.7915 23.036 29.7915 25.544C29.7915 25.901 29.8315 26.248 29.9085 26.58C26.1315 26.391 22.7835 24.581 20.5425 21.831C20.1515 22.503 19.9265 23.284 19.9265 24.117C19.9265 25.694 20.7295 27.084 21.9485 27.899C21.2035 27.875 20.5035 27.671 19.8905 27.331C19.8895 27.35 19.8895 27.368 19.8895 27.388C19.8895 29.59 21.4565 31.426 23.5355 31.844C22.8695 32.025 22.1675 32.053 21.4825 31.922C22.0615 33.727 23.7395 35.041 25.7275 35.078C23.7835 36.602 21.3725 37.237 19.0005 36.959C21.0115 38.248 23.3995 39 25.9655 39C34.3245 39 38.8935 32.076 38.8935 26.071C38.8935 25.873 38.8905 25.679 38.8815 25.483C39.7685 24.843 40.8355 24.246 41.4445 23.334Z"
      fill="#566B76"
    />
  </svg>
);
export default SVG;
