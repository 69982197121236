import React from "react";
import harpaimg from "../static/img/lance.png";
import inside from "../static/img/harpa2.jpg";

export default function Content() {
  return (
    <div className="has-background-primary page-content">
      <section className="page-1 ">
        <div className="container has-text-gray width-6">
          <div className="heading-text type-3 heading-text-wtih-pimary  spacetwo">
            <p>Unique Location</p>
          </div>
          <div className="body-text body-text-with-primary black spacesix type-1">
            <p>
              Take this opportunity to visit the land of fire and ice.
              Remarkable landscapes, wild weather and untamed nature sets itself
              as the backdrop to Fin42.
            </p>
          </div>
          <p className="heading-text type-1 heading-text-wtih-pimary black spacethree ">
            Iconic Venue
          </p>
          <p className="body-text type-3 black body-text-with-primary is-hidden-mobile is-hidden-touch spacethree">
            Held in one of Reykjavik’s most distinguished landmarks, Harpa
            Concert Hall. This iconic building with honeycombed windowpanes
            overlook the sea to one side and Reykjavik city center to the other.
          </p>
          <div className="columns">
            <div className="column is-half">
              <figure className="image is-4by3">
                <img alt="harpa" src={harpaimg} />
              </figure>
            </div>
            <div className="column is-hidden-mobile is-hidden-touch is-half">
              <figure className="image is-4by3">
                <img alt="InsideHarpa" src={inside} />
              </figure>
            </div>
          </div>
          <p className="body-text type-3 is-hidden-desktop spacethree">
            Held in one of Reykjavik’s most distinguished landmarks, Harpa
            Concert Hall. This iconic building with honeycombed windowpanes
            overlook the sea to one side and Reykjavik city center to the other.
          </p>
        </div>
        <div className="zig-1">&nbsp; &nbsp;</div>
      </section>
    </div>
  );
}
