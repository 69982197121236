import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import Hero02 from "./Hero02";
import Hero03 from "./Hero03";
import Speakers from "./Speakers";
import Attend from "./Attend";
import Contact from "./Contact";
import Highlights from "./Highlights";
import Sponsors from "./Sponsors";
import Map from "./Map";
import * as Data from "../../auth/Data";

export default function Index() {
  const [data, setData] = useState([""]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const tempData = await Data.getFrontPage();
      // convert data to json
      setData(tempData);
      setLoading(false);
    };
    fetchData();
  }, [loading]);

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          <Hero />
          <Attend content={data} />
          <Speakers />
          <Contact content={data} />
          <Highlights content={data} />
          <Sponsors />
          <Map content={data} />
        </>
      )}
    </div>
  );
}
