import React from "react";
import Twitter from "../static/img/Twitter";
import Facebook from "../static/img/Facebook";
import Linkedin from "../static/img/Linkedin";

export default function Footer() {
  return (
    <div className=" footer">
      <section className="">
        <div className=" container">
          <div className="columns">
            <div className="column is-one-quarter ">
              <p className="heading-text has-text-danger type-1">Organizer</p>
              <p className="body-text">
                <a className=" body-text-color" href="http://www.meniga.com">
                  <u>Meniga</u>
                </a><br />
                The Cursitor 38 Chancery Lane, London <br />
                WC2A1EN
                <br /> United Kingdom
              </p>
            </div>
            <div className="column is-one-quarter ">
              <p className="heading-text has-text-danger type-1">Co-organizer</p>
              <p className="body-text">
                <a className=" body-text-color" href="https://www.fjartaekniklasinn.is/">
                  <u>The Fintech Cluster  </u>
                </a>
                Fjártækniklasinn <br />
                Bjargargata 1, 102 Reykjavík
                <br /> Iceland
              </p>
            </div>
            <div className="column is-one-quarter  ">
              <p className="heading-text has-text-danger type-1">Information</p>
              <p className="body-text">
                <a className=" body-text-color" href="mailto:info@meniga.com">
                  <u>info@meniga.com</u>
               </a><br />
                T: +44 (0)203 865 2663
              </p>
            </div>
            <div className="column is-one-quarter ">
              <p className="heading-text has-text-danger type-1">
                Committee Head
              </p>
              <p className="body-text">
                <u>Eleni Vlami</u>
                <br /> Tel: +44 7584 139596 <br /> Email: eleni@meniga.com
              </p>
            </div>
          </div>
          <div className="divider"></div>
          <div>
            <div className="social-icons">
              <div className="icon is-large		">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/meniga"
                >
                  <Twitter />
                </a>
              </div>
              <div className="icon is-large		">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/meniga/"
                >
                  <Facebook />
                </a>
              </div>
              <div className="icon is-large		">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/meniga/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
            <div>
              <p className="is-pulled-right copy">
                © Copyright 2022 | All Rights Reserved | Web design by Meniga
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
