import React from "react";
import water from "../static/img/water.png";
import Arrow from "../static/img/arrow";

export default function Adventure() {
  return (
    <div className="has-background-primary adventure">
      <div className="container">
        <div className="heading-text has-text-link  font-1 ">
          <p>Set out on an adventure</p>
        </div>
        <div className="columns is-multiline">
          <div className="column spacethree is-three-fifths">
            <div className="body-text type-2">
              <p>
                Our team has organized a private trip for those interested in
                being whisked to some of Iceland’s highlights.
              </p>
              <br />
              <p>
                Lasting 8 hours, this private tour will take you to the famed
                Golden Circle while stopping for a special visit and lunch at a
                rural greenhouse Friðheimar.
              </p>
            </div>
          </div>
          <div className="column spacethree is-hidden-mobile is-two-fifths">
            <img alt="Water" src={water} />
          </div>
        </div>
        <div className="column">
          <div className="is-pulled-right arrowLink">
            <div className="font-1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/"
              >
                View Tour
              </a>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
      <div className="zig-10">&nbsp; &nbsp;</div>
    </div>
  );
}
