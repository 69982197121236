import React, { useState, useEffect } from "react";
import Youtube from "react-youtube";
import ArrowLink from "../Elements/ArrowLink";
import Star from "../static/img/Star";
import Half from "../static/img/Half";

export default function Highlights({ content }) {
  const [title, setTitle] = useState(["", "", ""]);

  const videoOptions = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 1,
      cc_load_policy: 0,
      mute: 1,
    },
  };
  return (
    <div className="has-background-success ">
      <section className="highlights">
        <div className="container">
          <div className="heading-text type-3 width-65 spacethree has-text-link">
            <p>{content[2][0]}</p>
          </div>
          <div className="body-text type-3 width-65 spacesix white">
            <p>{content[2][1]}</p>

            <div className="is-hidden-mobile is-hidden-touch">
              <ArrowLink url="/events" text="Watch more videos" color={true} />
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <Youtube
                videoId="g6ZUGOVZhBo"
                className="video"
                opts={videoOptions}
              />
            </div>
            <div className="column is-half">
              <Youtube
                videoId="yUNBg7xKlK8"
                className="video"
                opts={videoOptions}
              />
            </div>
            <div className="is-hidden-desktop spacesix">
              <ArrowLink url="/events" text="Watch more videos" />
            </div>
          </div>
          <div className="columns">
            <div className="column raiting darkyello is-one-third">
              <div className="heading-text type-3">
                <p>4.5</p>
              </div>
              <Star />
              <Star />
              <Star />
              <Star />
              <Half />
              <div className="body-text type-4">
                <p>2018’s attendees rating</p>
              </div>
            </div>
            <div className="column is-two-thirds">
              <div className="body-text white type-4">
                <div className="heading-text type-precent white">
                  <p>“</p>
                </div>
                <p>{content[2][2]}</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
