import React from "react";
import Hero from "./Hero";
import Hotels from "./Hotels";
import Content from "./Content";
import Info from "./Info";
import Adventure from "./Adventure";

export default function index() {
  return (
    <div>
      <Hero />
      <Content />
      <Hotels />
      <Info />
    </div>
  );
}
