import React, { useState, useEffect } from "react";
import img from "../static/img/image002.png";
import img2 from "../static/img/image.png";
import * as Data from "../../auth/Data";

export default function Content() {
  const [data, setData] = useState([""]);
  const [schedule, setSchedule] = useState([]);
  const [active, setActive] = useState([]);
  const [active2, setActive2] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const path = "/speakers";
  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const tempData2 = await Data.getSchedule();
      // convert data to json
      setSpeakers(await Data.getSpeakers());
      setSchedule(tempData2);
      setLoading(false);

      setActive(tempData2[0][1]);
      setActive2(tempData2[0][0]);
    };
    fetchData();
  }, [loading]);
  if (loading) {
  }
  return (
    <div className="has-background-success page-content">
      {loading ? (
        <></>
      ) : (
        <>
          <section className="page-1 ">
            <div className=" container white">
              <div className="tabs">
                <ul>
                  {schedule.map((item, index) => (
                    <li
                      className={
                        item[0] == active2 ? "is-active tabsitem" : "tabsitem"
                      }
                      onClick={() => {
                        setActive(item[1]);
                        setActive2(item[0]);
                      }}
                    >
                      <a>
                        <span className="normal">{item[0]}</span>
                        <span>{item[2]}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {active.map((events, index) => (
                <>
                  {events.type !== active[index].type || index == 0 ? (
                    <div className="heading-text width-6 spacethree type-1 has-text-link">
                      <p>{events.type}</p>
                    </div>
                  ) : events.type !== active[index - 1].type ? (
                    <div className="heading-text width-6 spacethree type-1 has-text-link">
                      <p>{events.type}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="  white spacesix">
                    <div className="body-text type-2 flexschedule">
                      <div className="short pr">
                        <p>{events.time}</p>
                      </div>
                      <div className="long-and-truncated ">
                        <p>
                          ...........................................................................................................................................................................................................................................................................................................................................................................
                        </p>
                      </div>
                      <div className="short pl">
                        <p>{events.end}</p>{" "}
                      </div>
                    </div>
                    <div className="body-text type-3 font-bold ">
                      <p>{events.name}</p>
                    </div>
                    <div className="body-text type-2">
                      {speakers
                        .filter((speaker) => speaker[7] == events.speaker.id)
                        .map((newSpeaker) => (
                          <>
                            <a
                              className="font-bold"
                              href={path + "#" + newSpeaker[0].split(" ")[0]}
                            >
                              {newSpeaker[0]}
                            </a>
                            <p>{newSpeaker[3]}</p>
                          </>
                        ))}
                    </div>
                  </div>
                </>
              ))}
              <div></div>{" "}
            </div>
          </section>
        </>
      )}
    </div>
  );
}
