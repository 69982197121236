import React from "react";

export default function Travel({ title, bodytext, url }) {
  const Icon = (
    <svg
      viewBox="0 0 24 24"
      id="linkicon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7208 13.5701L22.5298 9.4561C24.0608 7.8041 23.3718 4.7841 21.7578 3.2891L19.9348 1.6011C19.1818 0.902103 18.0598 0.413103 16.9328 0.290103C15.6048 0.144103 14.4658 0.504103 13.7268 1.3021L9.91578 5.4151C8.79178 6.6301 8.86578 8.5311 10.0788 9.6551L10.1678 9.7381C10.5738 10.1131 11.2058 10.0891 11.5808 9.6831C11.9568 9.2781 11.9318 8.6451 11.5268 8.2701L11.4378 8.1881C11.0338 7.8141 11.0088 7.1791 11.3838 6.7751L15.1938 2.6611C15.4878 2.3431 16.0418 2.2041 16.7148 2.2771C17.4198 2.3541 18.1328 2.6571 18.5748 3.0671L20.3998 4.7561C21.2638 5.5571 21.7498 7.3561 21.0628 8.0971L17.2528 12.2111C16.8778 12.6151 16.2438 12.6391 15.8398 12.2651L15.7508 12.1821C15.3468 11.8071 14.7138 11.8311 14.3378 12.2361C13.9628 12.6411 13.9868 13.2741 14.3918 13.6501L14.4808 13.7321C15.0578 14.2661 15.7888 14.5311 16.5188 14.5311C17.3248 14.5311 18.1298 14.2061 18.7208 13.5701Z"
        fill="#B3B3B3"
      />
      <path
        d="M9.5156 10.2635C8.9286 9.71853 8.1646 9.43753 7.3626 9.46553C6.5616 9.49753 5.8216 9.83753 5.2766 10.4255L1.4666 14.5385C0.727596 15.3365 0.455596 16.4995 0.701596 17.8135C0.911596 18.9265 1.4856 20.0085 2.2396 20.7055L4.0626 22.3945C4.9636 23.2305 6.3186 23.7415 7.6046 23.7415C8.6206 23.7415 9.5946 23.4225 10.2706 22.6925L14.0796 18.5795C15.2046 17.3655 15.1316 15.4645 13.9176 14.3405L13.8276 14.2575C13.4226 13.8815 12.7896 13.9075 12.4146 14.3115C12.0396 14.7175 12.0646 15.3495 12.4696 15.7245L12.5586 15.8075C12.9636 16.1815 12.9876 16.8165 12.6136 17.2205L8.8036 21.3335C8.1176 22.0765 6.2866 21.7285 5.4206 20.9275L3.5976 19.2395C3.1556 18.8285 2.7986 18.1405 2.6676 17.4445C2.5426 16.7785 2.6396 16.2155 2.9336 15.8975L6.7436 11.7845C6.9256 11.5885 7.1716 11.4745 7.4396 11.4645C7.7006 11.4535 7.9606 11.5485 8.1576 11.7315L8.2456 11.8135C8.6526 12.1885 9.2856 12.1635 9.6596 11.7575C10.0346 11.3525 10.0096 10.7205 9.6046 10.3445L9.5156 10.2635Z"
        fill="#B3B3B3"
      />
      <path
        d="M8.261 17.0333C8.018 17.0333 7.774 16.9443 7.582 16.7663C7.177 16.3913 7.152 15.7583 7.527 15.3533L15.002 7.28328C15.378 6.87728 16.011 6.85328 16.415 7.22828C16.82 7.60328 16.845 8.23628 16.47 8.64128L8.995 16.7123C8.798 16.9253 8.529 17.0333 8.261 17.0333Z"
        fill="#B3B3B3"
      />
    </svg>
  );
  return (
    <div className="travel-body black">
      <div className="columns is-multiline">
        <div className="column is-two-thirds">
          <div className="heading-text spacethree type-1 width-7">
            <p>{title}</p>
          </div>
          <div className="body-text spacesix width-95 type-4">
            {bodytext.map((bio) => (
              <>
                <p>{bio.text}</p>
                <br />
              </>
            ))}
          </div>
        </div>

        {url.length <= 1 ? (
          url.map((link) => (
            <>
              <div className="column is-one-third">
                <div className="columns">
                  <div className="column is-four-fifths">
                    <a
                      className="body-text-color font-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.link.url}
                    >
                      {link.name_of_link[0].text}
                    </a>
                  </div>
                  <div className="column auto"> {Icon}</div>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div className="column  is-one-third"></div>
            {url.map((link) => (
              <div className="column underline flexing is-one-third">
                <div className="columns">
                  <div className="column is-four-fifths">
                    <a
                      className="body-text-color  font-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.link.url}
                    >
                      {link.name_of_link[0].text}
                    </a>
                  </div>
                  <div className="column auto"> {Icon}</div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
