import React, { useState, useEffect } from "react";
import ArrowLink from "../Elements/ArrowLink";

export default function GoogleMap({ content }) {
 
  return (
    <>
      <div className="has-background-success">
        <section className="map">
          <div className=" container">
            <div className="heading-text type-3 has-text-link ">
              <p>{content[3][0]}</p>
            </div>
            <br />
            <div className="body-text width-5 white spacesix type-3">
              <p>{content[3][1]}</p>

              <div className="is-hidden-mobile is-hidden-touch">
                <ArrowLink url="/location" text="See more" color={true} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="has-background-primary gframe">
        <iframe
          width="100%"
          height="450"
          title="GMaps"
          scrolling="no"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC5l6vZximGN613Q5_JtPtRppyJMAnwUCY&q=Austurbakki 2, 101 Reykjavík, Iceland`}
        ></iframe>
        <div className="is-hidden-desktop ">
          <ArrowLink url="/location" text="See more" color={true} />
        </div>{" "}
      </div>
    </>
  );
}
