import React from "react";

const SVG = () => (
  <svg
    viewBox="0 0 800 485"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="harpa-icon"
  >
    <path
      d="M0.681197 226.08H700.319C653.041 375.863 514.422 484.455 350.5 484.455C186.578 484.455 47.9578 376.069 0.681197 226.08Z"
      fill="#F4F4F0"
      stroke="#2E8DD1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.314737 226.58C0.209374 226.247 0.104462 225.913 0 225.58H701C700.895 225.913 700.79 226.247 700.685 226.58C653.24 376.381 514.522 484.955 350.5 484.955C186.478 484.955 47.7593 376.586 0.314737 226.58ZM1.3637 226.58C48.7728 376.032 187.042 483.955 350.5 483.955C513.956 483.955 652.226 375.828 699.636 226.58H1.3637Z"
      fill="#2E8DD1"
    />
    <path d="M530 311H265V316H530V311Z" fill="#F4F4F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M529.001 311.893H265.999V315.107H529.001V311.893ZM530 311V316H265V311H530Z"
      fill="black"
    />
    <path d="M462 332H300V338H462V332Z" fill="#F4F4F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M460.998 333.071H301.002V336.929H460.998V333.071ZM462 332V338H300V332H462Z"
      fill="black"
    />
    <path d="M429 384H342V389H429V384Z" fill="#F4F4F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M427.999 384.893H343.001V388.107H427.999V384.893ZM429 384V389H342V384H429Z"
      fill="black"
    />
    <path d="M495 357H361V363H495V357Z" fill="#F4F4F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M493.999 358.071H362.001V361.929H493.999V358.071ZM495 357V363H361V357H495Z"
      fill="black"
    />
    <path
      d="M172.381 382.325C172.381 364.079 186.934 349.151 205.176 349.151C223.419 349.151 237.972 363.872 237.972 382.325"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M205.176 350.151C187.501 350.151 173.381 364.617 173.381 382.325H172.381C172.381 364.079 186.934 349.151 205.176 349.151C223.419 349.151 237.972 363.872 237.972 382.325H236.972C236.972 364.413 222.856 350.151 205.176 350.151Z"
      fill="black"
    />
    <path d="M247.605 382.326L268.512 360.141V382.326" fill="#F4F4F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M268.512 382.326V360.141L247.605 382.326H248.98L267.512 362.66V382.326H268.512Z"
      fill="black"
    />
    <path
      d="M189.394 375.901C191.658 375.901 193.494 374.043 193.494 371.752C193.494 369.461 191.658 367.604 189.394 367.604C187.13 367.604 185.295 369.461 185.295 371.752C185.295 374.043 187.13 375.901 189.394 375.901Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M189.394 374.901C191.095 374.901 192.494 373.502 192.494 371.752C192.494 370.002 191.095 368.604 189.394 368.604C187.694 368.604 186.295 370.002 186.295 371.752C186.295 373.502 187.694 374.901 189.394 374.901ZM193.494 371.752C193.494 374.043 191.658 375.901 189.394 375.901C187.13 375.901 185.295 374.043 185.295 371.752C185.295 369.461 187.13 367.604 189.394 367.604C191.658 367.604 193.494 369.461 193.494 371.752Z"
      fill="black"
    />
    <path
      d="M188.783 106.691C215.725 106.691 237.566 84.5982 237.566 57.3455C237.566 30.0927 215.725 8 188.783 8C161.841 8 140 30.0927 140 57.3455C140 84.5982 161.841 106.691 188.783 106.691Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M188.783 105.691C215.162 105.691 236.566 84.0567 236.566 57.3455C236.566 30.6342 215.162 9 188.783 9C162.404 9 141 30.6342 141 57.3455C141 84.0567 162.404 105.691 188.783 105.691ZM237.566 57.3455C237.566 84.5982 215.725 106.691 188.783 106.691C161.841 106.691 140 84.5982 140 57.3455C140 30.0927 161.841 8 188.783 8C215.725 8 237.566 30.0927 237.566 57.3455Z"
      fill="#FEB734"
    />
    <path
      d="M364.233 259.375L70.9203 246.728L67.8457 160.684L357.469 124.401L364.233 259.375Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M364.233 259.375L357.469 124.401L67.8457 160.684L70.9203 246.728L364.233 259.375ZM363.18 258.329L356.525 125.527L68.8777 161.563L71.8866 245.769L363.18 258.329Z"
      fill="black"
    />
    <path
      d="M607.123 252.533H89.1621V272.023H607.123V252.533Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M606.123 253.533H90.1621V271.023H606.123V253.533ZM607.123 252.533V272.023H89.1621V252.533H607.123Z"
      fill="#F4F4F0"
    />
    <path
      d="M629.465 255.643H356.444L337.997 0L638.074 88.1171L629.465 255.643Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M628.516 254.643L637.035 88.8542L339.098 1.36557L357.375 254.643H628.516ZM356.444 255.643H629.465L638.074 88.1171L337.997 0L356.444 255.643Z"
      fill="black"
    />
    <path
      d="M363.208 255.643L58.2119 258.546L61.0815 208.578L356.444 252.119L363.208 255.643Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.2119 258.546L61.0815 208.578L356.444 252.119L363.208 255.643L58.2119 258.546ZM359.199 254.681L59.2716 257.536L62.0172 209.727L356.132 253.083L359.199 254.681Z"
      fill="black"
    />
    <path
      d="M373.457 80.8605H383.091L386.985 64.6882H377.351L373.457 80.8605Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M382.303 79.8605L385.716 65.6882H378.139L374.726 79.8605H382.303ZM373.457 80.8605H383.091L386.985 64.6882H377.351L373.457 80.8605Z"
      fill="black"
    />
    <path
      d="M623.316 147.622H631.105L633.77 131.45H625.161L623.316 147.622Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M630.257 146.622L632.592 132.45H626.054L624.437 146.622H630.257ZM623.316 147.622H631.105L633.77 131.45H625.161L623.316 147.622Z"
      fill="black"
    />
    <path
      d="M611.633 165.245H621.266L623.316 149.073H613.478L611.633 165.245Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M620.385 164.245L622.181 150.073H614.37L612.753 164.245H620.385ZM611.633 165.245H621.266L623.316 149.073H613.478L611.633 165.245Z"
      fill="black"
    />
    <path
      d="M599.949 182.869H609.788L611.632 166.697H601.999L599.949 182.869Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M608.895 181.869L610.512 167.697H602.88L601.084 181.869H608.895ZM599.949 182.869H609.788L611.632 166.697H601.999L599.949 182.869Z"
      fill="black"
    />
    <path
      d="M405.433 34.8324H415.066L416.911 23.0143L407.482 20.7336L405.433 34.8324Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M414.21 33.8324L415.781 23.7698L408.314 21.9637L406.589 33.8324H414.21ZM405.433 34.8324H415.066L416.911 23.0143L407.482 20.7336L405.433 34.8324Z"
      fill="black"
    />
    <path
      d="M593.801 90.3978H603.434L605.279 78.5798L595.85 76.2991L593.801 90.3978Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M602.578 89.3978L604.149 79.3352L596.682 77.5291L594.957 89.3978H602.578ZM593.801 90.3978H603.434L605.279 78.5798L595.85 76.2991L593.801 90.3978Z"
      fill="black"
    />
    <path
      d="M531.693 76.2992H541.532L545.221 60.5417L535.793 58.261L531.693 76.2992Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M540.739 75.2992L544.021 61.2803L536.543 59.4713L532.946 75.2992H540.739ZM531.693 76.2992H541.532L545.221 60.5417L535.793 58.261L531.693 76.2992Z"
      fill="black"
    />
    <path
      d="M380.221 96.8252H390.059L393.749 80.6531H384.115L380.221 96.8252Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M389.262 95.8252L392.495 81.6531H384.903L381.49 95.8252H389.262ZM380.221 96.8252H390.059L393.749 80.6531H384.115L380.221 96.8252Z"
      fill="black"
    />
    <path
      d="M366.692 114.034H376.326L380.22 97.8618H370.587L366.692 114.034Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M375.538 113.034L378.951 98.8618H371.375L367.962 113.034H375.538ZM366.692 114.034H376.326L380.22 97.8618H370.587L366.692 114.034Z"
      fill="black"
    />
    <path
      d="M353.985 182.869H363.618L362.183 166.697H352.55L353.985 182.869Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M363.618 182.869L362.183 166.697H352.55L353.985 182.869H363.618ZM353.642 167.697L354.9 181.869H362.526L361.268 167.697H353.642Z"
      fill="black"
    />
    <path
      d="M436.383 196.76H446.016L450.936 183.491H441.302L436.383 196.76Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M445.321 195.76L449.499 184.491H441.998L437.82 195.76H445.321ZM436.383 196.76H446.016L450.936 183.491H441.302L436.383 196.76Z"
      fill="black"
    />
    <path
      d="M443.352 212.725H452.985L458.109 198.626H448.271L443.352 212.725Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M452.285 211.725L456.682 199.626H448.981L444.76 211.725H452.285ZM443.352 212.725H452.985L458.109 198.626H448.271L443.352 212.725Z"
      fill="black"
    />
    <path
      d="M471.022 255.644H480.656L482.501 244.447H472.867L471.022 255.644Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M479.807 254.644L481.322 245.447H473.716L472.201 254.644H479.807ZM471.022 255.644H480.656L482.501 244.447H472.867L471.022 255.644Z"
      fill="black"
    />
    <path
      d="M391.904 255.646H401.538L403.588 248.801H393.749L391.904 255.646Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M400.794 254.646L402.244 249.801H394.515L393.209 254.646H400.794ZM391.904 255.646H401.538L403.588 248.801H393.749L391.904 255.646Z"
      fill="black"
    />
    <path
      d="M553.626 238.434H563.259L567.154 222.262H557.52L553.626 238.434Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M562.472 237.434L565.885 223.262H558.308L554.895 237.434H562.472ZM553.626 238.434H563.259L567.154 222.262H557.52L553.626 238.434Z"
      fill="black"
    />
    <path
      d="M584.166 209.408H593.8L595.85 193.236H586.011L584.166 209.408Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M592.919 208.408L594.715 194.236H586.903L585.287 208.408H592.919ZM584.166 209.408H593.8L595.85 193.236H586.011L584.166 209.408Z"
      fill="black"
    />
    <path
      d="M497.465 163.794H507.098L510.993 149.696H501.359L497.465 163.794Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M506.337 162.794L509.679 150.696H502.12L498.779 162.794H506.337ZM497.465 163.794H507.098L510.993 149.696H501.359L497.465 163.794Z"
      fill="black"
    />
    <path
      d="M464.26 93.7149H473.893L475.738 77.543H466.104L464.26 93.7149Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M473.001 92.7149L474.617 78.543H466.997L465.38 92.7149H473.001ZM464.26 93.7149H473.893L475.738 77.543H466.104L464.26 93.7149Z"
      fill="black"
    />
    <path
      d="M473.893 111.753H483.526L485.371 95.5811H475.737L473.893 111.753Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M482.634 110.753L484.25 96.5811H476.63L475.013 110.753H482.634ZM473.893 111.753H483.526L485.371 95.5811H475.737L473.893 111.753Z"
      fill="black"
    />
    <path
      d="M540.098 255.643H549.936L553.831 238.642H543.992L540.098 255.643Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M549.139 254.643L552.576 239.642H544.789L541.353 254.643H549.139ZM540.098 255.643H549.936L553.831 238.642H543.992L540.098 255.643Z"
      fill="black"
    />
    <path
      d="M513.042 147.622H522.676L525.955 131.035H516.117L513.042 147.622Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M521.854 146.622L524.738 132.035H516.948L514.244 146.622H521.854ZM513.042 147.622H522.676L525.955 131.035H516.117L513.042 147.622Z"
      fill="black"
    />
    <path
      d="M82.1929 195.519H87.9321L87.3172 185.771H81.373L82.1929 195.519Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.9321 195.519L87.3172 185.771H81.373L82.1929 195.519H87.9321ZM82.4607 186.771L83.1123 194.519H86.8671L86.3783 186.771H82.4607Z"
      fill="black"
    />
    <path
      d="M276.505 227.239H283.474L280.81 215.42H273.841L276.505 227.239Z"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M283.474 227.239L280.81 215.42H273.841L276.505 227.239H283.474ZM275.091 216.42L277.305 226.239H282.224L280.01 216.42H275.091Z"
      fill="black"
    />
    <path
      d="M273.43 215.835L270.766 203.81H263.797L266.461 215.835"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M273.43 215.835L270.766 203.81H263.797L266.461 215.835L267.438 215.619L265.043 204.81H269.963L272.406 215.835H273.43Z"
      fill="black"
    />
    <path
      d="M277.326 203.81L274.661 191.992H267.692L270.357 203.81"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M277.326 203.81L274.661 191.992H267.692L270.357 203.81L271.332 203.59L268.943 192.992H273.862L276.301 203.81H277.326Z"
      fill="black"
    />
    <path
      d="M192.672 194.688L190.418 184.113H184.474L186.728 194.688"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.672 194.688L190.418 184.113H184.474L186.728 194.688L187.706 194.479L185.709 185.113H189.609L191.65 194.688H192.672Z"
      fill="black"
    />
    <path
      d="M202.511 205.883L200.257 195.309H194.107L196.567 205.883"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M202.511 205.883L200.257 195.309H194.107L196.567 205.883L197.541 205.657L195.367 196.309H199.447L201.489 205.883H202.511Z"
      fill="black"
    />
    <path
      d="M313.195 177.065L310.94 166.697H304.791L307.046 177.065"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M313.195 177.065L310.94 166.697H304.791L307.046 177.065L308.023 176.853L306.032 167.697H310.134L312.171 177.065H313.195Z"
      fill="black"
    />
    <path
      d="M241.661 235.121L239.816 226.202H233.667L235.512 234.291"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M241.661 235.121L239.816 226.202H233.667L235.512 234.291L236.487 234.069L234.921 227.202H239.002L240.61 234.979L241.661 235.121Z"
      fill="black"
    />
    <path
      d="M138.766 219.984L137.536 213.969H131.387L132.617 219.155"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M138.766 219.984L137.536 213.969H131.387L132.617 219.155L133.59 218.924L132.652 214.969H136.72L137.716 219.843L138.766 219.984Z"
      fill="black"
    />
    <path
      d="M327.748 137.88L325.493 128.547L319.754 129.17L322.009 138.502"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M327.748 137.88L325.493 128.547L319.754 129.17L322.009 138.502L322.981 138.268L320.993 130.041L324.727 129.636L326.745 137.989L327.748 137.88Z"
      fill="black"
    />
    <path
      d="M210.095 152.6L207.841 143.268L202.102 143.89L204.356 153.222"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M210.095 152.6L207.841 143.268L202.102 143.89L204.356 153.222L205.328 152.987L203.341 144.762L207.075 144.357L209.093 152.709L210.095 152.6Z"
      fill="black"
    />
    <path
      d="M149.833 156.332L148.398 150.732L142.864 151.354L144.094 157.162"
      fill="#F4F4F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.833 156.332L148.398 150.732L142.864 151.354L144.094 157.162L145.072 156.955L144.071 152.225L147.646 151.823L148.838 156.476L149.833 156.332Z"
      fill="black"
    />
  </svg>
);

export default SVG;
