import React from "react";

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    id="play"
    viewBox="0 0 26 26"
  >
    <circle cx="13" cy="13" r="12" stroke="#fff" />
    <rect x="8.70001" y="7.5" width="3" height="11" stroke="#fff" />
    <rect x="14.3" y="7.5" width="3" height="11" stroke="#fff" />
  </svg>
);

export default SVG;
