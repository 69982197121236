import React from "react";
import Events from "../static/img/Events";

export default function Hero() {
  return (
    <div className="banner">
      <section className="hero">
        <div className="hero-body">
          <div className="container  has-text-right">
            <div className="heading-text">
              <p>
                <span className="white">This is </span>
                <span className="has-text-link">how</span>
              </p>
              <p>
                <span className="has-text-link">we</span>
                <span className="white"> do it!</span>
              </p>
            </div>
            <p className="body-text white">
              <b>To be announced -</b> Reykjavik, Iceland
            </p>
          </div>
        </div>
      </section>
      <Events />
    </div>
  );
}
