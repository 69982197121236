import React, { useState, useEffect } from "react";
import * as Data from "../../auth/Data";
import LargeCard from "../Elements/LargeCard";

export default function Hero() {
  const [speakers, setSpeakers] = useState([]);
  const [data, setData] = useState([""]);
  useEffect(() => {
    async function fetch() {
      setSpeakers(await Data.getSpeakers());
      const tempData = await Data.getContentspeaker("page-speakers");
      // convert data to json
      setData(tempData);
    }
    fetch();
  }, []);
  /* {speakers.map((speaker, index) => {
              return (
                <LargeCard
                  title={speaker[0]}
                  type={speaker[1]}
                  img={speaker[2]}
                  company={speaker[3]}
                  timeslot={speaker[4]}
                  typeofspeaker={speaker[5]}
                  companylogo={speaker[6]}
                  even={index % 2 === 0}
                />
              );
            })}*/
  return (
    <div className="has-background-primary">
      <section className="page-1">
        <div className=" container">
          <div className="heading-text type-1 spacethree ">
            <p>{data[0][1]}</p>
          </div>
          <div className="body-text width-65 type-3 spacethree">
            <p>
              {/*data[0][0]*/}
              To be announced
              <br />
              <br />
            </p>
          </div>
          <div></div>
        </div>
      </section>
    </div>
  );
}
