import React from "react";
import Hero from "./Hero";
import Content from "./Content3";

export default function index() {
  return (
    <>
      <div className="has-background-success">
        <Hero />
        <div className="fix "></div>

        <Content />
      </div>
    </>
  );
}
