import React from "react";
import Arrow from "../static/img/arrow";

export default function ArrowLink({ url, text, color }) {
  return (
    <div className="is-pulled-right arrowLink">
      <div className={color ? "white": "black"}>
        <a href={url}>{text}</a>
        <Arrow fill={color ? "white": "black"} />
      </div>
    </div>
  );
}
