import React from "react";

const SVG = () => (
  <svg
    viewBox="0 0 637 290"
    fill="none"
    id="speakerhouse"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1475_493)">
      <path
        d="M637 290C637 129.833 507.265 0 347.22 0C187.176 0 57.4414 129.833 57.4414 290"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M637 290C637 129.833 507.265 0 347.22 0C187.176 0 57.4414 129.833 57.4414 290H58.4414C58.4414 130.385 187.729 1 347.22 1C506.712 1 636 130.385 636 290H637Z"
        fill="black"
      />
      <path
        d="M568.628 290C568.628 163.511 466.186 60.9915 339.794 60.9915C213.401 60.9915 110.96 163.511 110.96 290"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M568.628 290C568.628 163.511 466.186 60.9915 339.794 60.9915C213.401 60.9915 110.96 163.511 110.96 290H111.96C111.96 164.063 213.954 61.9915 339.794 61.9915C465.633 61.9915 567.628 164.063 567.628 290H568.628Z"
        fill="black"
      />
      <path
        d="M573.455 290H563.8C563.8 259.76 557.859 230.402 546.208 202.763C534.929 176.053 518.776 152.084 498.214 131.506C477.651 110.927 453.7 94.762 427.01 83.4742C399.393 71.7683 370.057 65.8689 339.84 65.8689C309.623 65.8689 280.287 71.8147 252.67 83.4742C225.934 94.762 201.983 110.927 181.42 131.506C160.857 152.084 144.705 176.053 133.425 202.763C121.728 230.402 115.833 259.76 115.833 290H106.179C106.179 258.413 112.352 227.801 124.56 199C136.35 171.176 153.152 146.138 174.643 124.677C196.088 103.216 221.106 86.3542 248.91 74.5554C277.735 62.3385 308.323 56.1604 339.84 56.1604C371.357 56.1604 401.992 62.3385 430.77 74.5554C458.574 86.3542 483.592 103.17 505.037 124.677C526.481 146.138 543.331 171.176 555.12 199C567.281 227.801 573.455 258.413 573.455 290Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M546.208 202.763C557.859 230.402 563.8 259.76 563.8 290H573.455C573.455 289.666 573.454 289.333 573.453 289C573.324 257.766 567.153 227.497 555.12 199C543.331 171.176 526.481 146.138 505.037 124.677C483.592 103.17 458.574 86.3542 430.77 74.5554C401.992 62.3385 371.357 56.1604 339.84 56.1604C308.323 56.1604 277.735 62.3385 248.91 74.5554C221.106 86.3542 196.088 103.216 174.643 124.677C153.152 146.138 136.35 171.176 124.56 199C112.481 227.497 106.31 257.766 106.181 289C106.179 289.333 106.179 289.666 106.179 290H115.833C115.833 259.76 121.728 230.402 133.425 202.763C144.705 176.053 160.857 152.084 181.42 131.506C201.983 110.927 225.934 94.762 252.67 83.4742C280.287 71.8147 309.623 65.8689 339.84 65.8689C370.057 65.8689 399.393 71.7683 427.01 83.4742C453.7 94.762 477.651 110.927 498.214 131.506C518.776 152.084 534.929 176.053 546.208 202.763ZM427.401 82.5535C454.209 93.8918 478.268 110.13 498.921 130.799C519.574 151.468 535.8 175.545 547.13 202.374C558.704 229.831 564.669 258.979 564.798 289H572.453C572.324 257.899 566.179 227.761 554.199 199.389C542.459 171.683 525.683 146.754 504.329 125.384L504.329 125.383C482.976 103.968 458.066 87.2249 430.38 75.4759C401.726 63.3122 371.224 57.1604 339.84 57.1604C308.456 57.1604 278 63.312 249.3 75.4761C221.615 87.2247 196.704 104.014 175.351 125.384L175.35 125.385C153.951 146.754 137.221 171.683 125.48 199.391C113.455 227.762 107.31 257.899 107.181 289H114.836C114.964 258.981 120.883 229.833 132.504 202.374C143.834 175.545 160.06 151.468 180.713 130.799C201.366 110.13 225.425 93.8914 252.281 82.5529C280.022 70.8414 309.49 64.8689 339.84 64.8689C370.189 64.8689 399.658 70.7945 427.401 82.5535Z"
        fill="#88BC76"
      />
      <path
        d="M491.901 290C491.901 200.487 419.398 127.882 329.907 127.882C240.416 127.882 167.913 200.44 167.913 290"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M491.901 290C491.901 200.487 419.398 127.882 329.907 127.882C240.416 127.882 167.913 200.44 167.913 290H168.913C168.913 200.992 240.969 128.882 329.907 128.882C418.845 128.882 490.901 201.038 490.901 290H491.901Z"
        fill="black"
      />
      <path
        d="M496.543 290H487.26C487.26 268.725 483.082 248.1 474.913 228.73C466.976 209.963 455.65 193.148 441.168 178.655C426.686 164.161 409.883 152.827 391.131 144.93C371.729 136.708 351.12 132.574 329.908 132.574C308.649 132.574 288.04 136.755 268.684 144.93C249.932 152.874 233.129 164.208 218.647 178.655C204.211 193.101 192.839 209.963 184.949 228.73C176.733 248.147 172.602 268.771 172.602 290H163.318C163.318 267.471 167.728 245.638 176.408 225.107C184.809 205.272 196.831 187.434 212.102 172.105C227.42 156.776 245.244 144.791 265.064 136.383C285.626 127.697 307.442 123.284 329.908 123.284C352.373 123.284 374.236 127.697 394.752 136.383C414.572 144.791 432.396 156.822 447.713 172.105C463.031 187.434 475.006 205.272 483.407 225.107C492.134 245.638 496.543 267.471 496.543 290Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M268.684 144.93C288.04 136.755 308.649 132.574 329.908 132.574C351.12 132.574 371.729 136.708 391.131 144.93C409.883 152.827 426.686 164.161 441.168 178.655C455.65 193.148 466.976 209.963 474.913 228.73C483.082 248.1 487.26 268.725 487.26 290H496.543C496.543 289.667 496.542 289.333 496.54 289C496.412 266.825 492.005 245.334 483.407 225.107C475.006 205.272 463.031 187.434 447.713 172.105C432.396 156.822 414.572 144.791 394.752 136.383C374.235 127.697 352.373 123.284 329.908 123.284C307.442 123.284 285.626 127.697 265.064 136.383C245.244 144.791 227.42 156.776 212.102 172.105C196.831 187.434 184.809 205.272 176.408 225.107C167.856 245.334 163.45 266.825 163.321 289C163.319 289.333 163.318 289.667 163.318 290H172.602C172.602 268.771 176.733 248.147 184.949 228.73C192.839 209.963 204.211 193.101 218.647 178.655C233.129 164.208 249.932 152.874 268.684 144.93ZM184.027 228.341C191.969 209.454 203.414 192.485 217.94 177.948L217.941 177.947C232.513 163.41 249.423 152.003 268.294 144.01L268.295 144.009C287.774 135.781 308.516 131.574 329.908 131.574C351.252 131.574 371.993 135.734 391.521 144.009C410.393 151.957 427.303 163.364 441.876 177.948C456.448 192.531 467.847 209.455 475.834 228.34L475.835 228.341C483.928 247.532 488.128 267.946 488.257 289H495.54C495.412 266.957 491.031 245.6 482.487 225.498L482.487 225.497C474.135 205.78 462.232 188.05 447.007 172.813C431.779 157.62 414.062 145.661 394.361 137.304C373.97 128.67 352.24 124.284 329.908 124.284C307.576 124.284 285.892 128.67 265.454 137.304C245.752 145.661 228.037 157.573 212.811 172.811C197.629 188.05 185.68 205.78 177.329 225.496C168.83 245.6 164.45 266.957 164.321 289H171.605C171.732 267.992 175.888 247.578 184.027 228.341Z"
        fill="black"
      />
      <path
        d="M412.251 290H247.982C253.924 249.912 288.411 219.161 330.14 219.161C371.868 219.161 406.356 249.912 412.251 290Z"
        fill="#F4F4F0"
      />
      <path
        d="M392.152 240.25C375.581 223.667 353.58 214.562 330.139 214.562C306.699 214.562 284.698 223.667 268.127 240.25C254.48 253.907 245.893 271.233 243.294 290H252.67C255.223 273.695 262.789 258.691 274.672 246.8C289.478 231.982 309.159 223.806 330.093 223.806C351.027 223.806 370.708 231.982 385.561 246.8C397.444 258.691 405.009 273.695 407.562 290H416.939C414.339 271.233 405.752 253.86 392.152 240.25Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M386.267 246.092L386.268 246.093C398.095 257.929 405.702 272.813 408.413 289H415.783C413.029 270.885 404.616 254.138 391.445 240.957C375.062 224.561 353.315 215.562 330.139 215.562C306.964 215.562 285.217 224.561 268.834 240.957C255.617 254.184 247.203 270.888 244.45 289H251.819C254.531 272.813 262.137 257.929 273.964 246.093C288.958 231.087 308.893 222.806 330.093 222.806C351.293 222.806 371.227 231.088 386.267 246.092ZM252.67 290H243.294C243.34 289.666 243.388 289.333 243.438 289C246.2 270.618 254.723 253.664 268.127 240.25C284.698 223.667 306.699 214.562 330.139 214.562C353.58 214.562 375.581 223.667 392.152 240.25C405.51 253.618 414.032 270.617 416.794 289C416.844 289.333 416.892 289.666 416.939 290H407.562C405.009 273.695 397.444 258.691 385.561 246.8C370.708 231.982 351.027 223.806 330.093 223.806C309.159 223.806 289.478 231.982 274.672 246.8C262.789 258.691 255.223 273.695 252.67 290Z"
        fill="#B9BEDF"
      />
      <path d="M95.6603 224.921H9.04688V290H95.6603V224.921Z" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M94.6603 225.921H10.0469V289H94.6603V225.921ZM95.6603 224.921V290H9.04688V224.921H95.6603Z"
        fill="black"
      />
      <path
        d="M52.9566 263.615H44.5088V290H52.9566H61.358V263.615H52.9566Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.5088 263.615H61.358V290H44.5088V263.615ZM45.5088 289H60.358V264.615H45.5088V289Z"
        fill="black"
      />
      <path d="M95.6134 224.921L52.3067 181.581L9 224.921" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.6134 224.921L52.3067 181.581L9 224.921H10.4137L52.3067 182.996L94.1998 224.921H95.6134Z"
        fill="black"
      />
      <path d="M155.66 224.921H69.0469V290H155.66V224.921Z" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154.66 225.921H70.0469V289H154.66V225.921ZM155.66 224.921V290H69.0469V224.921H155.66Z"
        fill="black"
      />
      <path
        d="M112.957 263.615H104.509V290H112.957H121.358V263.615H112.957Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.509 263.615H121.358V290H104.509V263.615ZM105.509 289H120.358V264.615H105.509V289Z"
        fill="black"
      />
      <path d="M155.613 224.921L112.307 181.581L69 224.921" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M155.613 224.921L112.307 181.581L69 224.921H70.4137L112.307 182.996L154.2 224.921H155.613Z"
        fill="black"
      />
      <path d="M215.517 224.921H128.903V290H215.517V224.921Z" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M214.517 225.921H129.903V289H214.517V225.921ZM215.517 224.921V290H128.903V224.921H215.517Z"
        fill="black"
      />
      <path
        d="M172.813 263.615H164.365V290H172.813H181.214V263.615H172.813Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M164.365 263.615H181.214V290H164.365V263.615ZM165.365 289H180.214V264.615H165.365V289Z"
        fill="black"
      />
      <path
        d="M215.47 224.921L172.163 181.581L128.856 224.921"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M215.47 224.921L172.163 181.581L128.856 224.921H130.27L172.163 182.996L214.056 224.921H215.47Z"
        fill="black"
      />
      <path d="M188.642 224.921H275.255V290H188.642V224.921Z" fill="#F4F4F0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M274.255 225.921H189.642V289H274.255V225.921ZM188.642 224.921V290H275.255V224.921H188.642Z"
        fill="black"
      />
      <path
        d="M275.255 224.921L231.948 181.581L188.642 224.921"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M275.255 224.921L231.948 181.581L188.642 224.921H190.055L231.948 182.996L273.841 224.921H275.255Z"
        fill="black"
      />
      <path
        d="M231.949 261.85H222.99V290H231.949H240.953V261.85H231.949Z"
        fill="#F4F4F0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M222.99 261.85H240.953V290H222.99V261.85ZM223.99 289H239.953V262.85H223.99V289Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1475_493">
        <rect width="637" height="290" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVG;
