import React from "react";
import Hotel from "../Elements/Hotel";
import Ice from "../static/img/canopy.jpg";
import Exeter from "../static/img/exeter.png";
import Marina from "../static/img/marina.png";

export default function Hotels() {
  return (
    <div className="has-background-success section-hotels">
      <section className="">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-half">
              <div className="heading-text has-text-link type-3">
                <p>Accommodations</p>
              </div>
            </div>
          </div>
          <div className="width-6">
            <p className="body-text  type-3 white spacesix">
              We have organized special rates for Fin42 attendees. Each option
              is within walking distance of Harpa Concert Hall as well as all
              shops and restaurants.
            </p>
          </div>
          <div className="columns is-multiline is-mobile">
            <Hotel
              name="Canopy Reykjavik Hotel"
              street="Smidjustigur 4, 101 Reykjavik, Iceland"
              price1="32.000"
              price2="35.000"
              img={Ice}
              link="
http://eventsathilton.com/show/5e317178c4e4cc5b635e043f"
            />
            <Hotel
              name="Exeter Hotel"
              street="Tryggvagata 12, 101 Reykjavik, Iceland"
              price1="22.510"
              price2="24.970"
              img={Exeter}
              link="
https://keaexeterhotel.book-onlinenow.net/index.aspx?specialoffer=Concept+Events"
            />
            <Hotel
              name="Reykjavik Marina Hotel"
              street="Myrargata 2, 101 Reykjavík, Iceland"
              price1="21.100"
              price2="24.100"
              img={Marina}
              link="https://be.synxis.com/?Hotel=59628&Chain=15503&configCode=icelandair&level=hotel&start=availresults&Adult=1&Group=2011CONCEP&arrive=2020-11-16&depart=2020-11-18&locale=en-US"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
