import React from "react";

export default function Hero() {
  return (
    <div className="size-01 has-background-success">
      <div className="banner has-background-primary">
        <section className="hero">
          <div className="hero-body">
            <div className="container has-text-right">
              <div className="heading-text">
                <p>
                  <span className="heading-text-wtih-pimary">This is </span>
                  <span className="has-text-link">how</span>
                </p>
                <p>
                  <span className="has-text-link">we</span>
                  <span className="heading-text-wtih-pimary"> do it!</span>
                </p>
              </div>
              <p className="body-text">
                <b>To be announced -</b> Reykjavik, Iceland
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
