import React from "react";
const SVG = () => (
  <svg
    fill="none"
    id="galleryArrow"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" fill="#566B76" />
    <path
      d="M24.481 44.6625C24.0282 45.1125 23.2923 45.1125 22.8395 44.6625C22.3868 44.2125 22.3868 43.4809 22.8395 43.0309L35.9477 29.9988L22.8395 16.9691C22.3868 16.5191 22.3868 15.7875 22.8395 15.3375C23.2923 14.8875 24.0282 14.8875 24.481 15.3375L38.411 29.1842C38.6386 29.4104 38.75 29.7058 38.75 29.9988C38.75 30.2942 38.6386 30.5896 38.411 30.8158L24.481 44.6625Z"
      fill="white"
    />
  </svg>
);

export default SVG;
