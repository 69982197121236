import React from "react";

const SVG = () => (
  <svg
    fill="none"
    viewBox="0 0 554 327"
    id="boat"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M396.979 180.268V115.85H411.357V180.268H396.979Z" fill="#F4F4F0" stroke="black"/>
<path d="M287.33 122.888H62.6698C52.6806 122.888 44.5 114.659 44.5 104.598V18.79C44.5 8.72861 52.6806 0.5 62.6698 0.5H287.33C297.319 0.5 305.5 8.72861 305.5 18.79V104.598C305.5 114.659 297.319 122.888 287.33 122.888Z" fill="#F4F4F0" stroke="black"/>
<mask id="path-3-inside-1_141_84" fill="white">
<path d="M395.226 175.114C395.226 117.194 348.723 70.145 291.474 70.145C234.226 70.145 187.723 117.194 187.723 175.114"/>
</mask>
<path d="M395.226 175.114C395.226 117.194 348.723 70.145 291.474 70.145C234.226 70.145 187.723 117.194 187.723 175.114" fill="#F4F4F0"/>
<path d="M396.226 175.114C396.226 116.652 349.286 69.145 291.474 69.145V71.145C348.16 71.145 394.226 117.735 394.226 175.114H396.226ZM291.474 69.145C233.662 69.145 186.723 116.652 186.723 175.114H188.723C188.723 117.735 234.789 71.145 291.474 71.145V69.145Z" fill="#FEB734" mask="url(#path-3-inside-1_141_84)"/>
<path d="M514.368 206.323H404.712L360.18 140.217H514.368V206.323Z" fill="#F4F4F0" stroke="black"/>
<path d="M32.553 174.708H560.5C560.233 258.37 493.115 326.101 410.375 326.101H182.677C99.9372 326.101 32.8195 258.37 32.553 174.708Z" fill="#F4F4F0" stroke="black"/>
<path d="M192.996 196.391V189.891H386.16V196.391H192.996Z" fill="#F4F4F0" stroke="black"/>
<path d="M218.749 225.445V218.944H336.137V225.445H218.749Z" fill="#F4F4F0" stroke="black"/>
<path d="M263.583 259.936V253.435H360.591V259.936H263.583Z" fill="#F4F4F0" stroke="black"/>
<path d="M249.689 295.55V289.049H312.237V295.55H249.689Z" fill="#F4F4F0" stroke="black"/>
<path d="M436.812 163.961V114.538H493.988V163.961H436.812Z" fill="#F4F4F0" stroke="black"/>
<path d="M396.979 122.539V114.539H411.542V122.539H396.979Z" fill="#F4F4F0" stroke="black"/>
<path d="M461.565 128.847C461.565 133.132 458.133 136.596 453.913 136.596C449.692 136.596 446.261 133.132 446.261 128.847C446.261 124.562 449.692 121.098 453.913 121.098C458.133 121.098 461.565 124.562 461.565 128.847Z" fill="#F4F4F0" stroke="black"/>
<path d="M484.539 129.22C484.539 133.504 481.108 136.968 476.887 136.968C472.667 136.968 469.235 133.504 469.235 129.22C469.235 124.936 472.667 121.473 476.887 121.473C481.108 121.473 484.539 124.936 484.539 129.22Z" fill="#F4F4F0" stroke="black"/>
<mask id="path-15-inside-2_141_84" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.799 87.609C121.774 87.6091 121.749 87.6092 121.723 87.6092C110.161 87.6092 100.788 78.126 100.788 66.4278C100.788 54.7297 110.161 45.2465 121.723 45.2465C125.897 45.2465 129.786 46.4825 133.052 48.6125C133.735 32.5575 146.816 19.7534 162.853 19.7534C177.769 19.7534 190.127 30.8303 192.331 45.2973C192.814 45.2636 193.302 45.2464 193.793 45.2464C205.355 45.2464 214.729 54.7297 214.729 66.4278C214.729 78.1259 205.355 87.6091 193.793 87.6091C193.774 87.6091 193.755 87.6091 193.736 87.609H121.799Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.799 87.609C121.774 87.6091 121.749 87.6092 121.723 87.6092C110.161 87.6092 100.788 78.126 100.788 66.4278C100.788 54.7297 110.161 45.2465 121.723 45.2465C125.897 45.2465 129.786 46.4825 133.052 48.6125C133.735 32.5575 146.816 19.7534 162.853 19.7534C177.769 19.7534 190.127 30.8303 192.331 45.2973C192.814 45.2636 193.302 45.2464 193.793 45.2464C205.355 45.2464 214.729 54.7297 214.729 66.4278C214.729 78.1259 205.355 87.6091 193.793 87.6091C193.774 87.6091 193.755 87.6091 193.736 87.609H121.799Z" fill="white"/>
<path d="M121.799 87.609V86.609L121.796 86.609L121.799 87.609ZM133.052 48.6125L132.506 49.4501L133.976 50.4094L134.051 48.655L133.052 48.6125ZM192.331 45.2973L191.343 45.4479L191.482 46.359L192.401 46.2948L192.331 45.2973ZM193.736 87.609L193.739 86.609H193.736V87.609ZM121.796 86.609C121.772 86.6091 121.747 86.6092 121.723 86.6092V88.6092C121.75 88.6092 121.776 88.6091 121.803 88.609L121.796 86.609ZM121.723 86.6092C110.724 86.6092 101.788 77.5847 101.788 66.4278H99.7876C99.7876 78.6672 109.598 88.6092 121.723 88.6092V86.6092ZM101.788 66.4278C101.788 55.271 110.724 46.2465 121.723 46.2465V44.2465C109.598 44.2465 99.7876 54.1885 99.7876 66.4278H101.788ZM121.723 46.2465C125.697 46.2465 129.397 47.4225 132.506 49.4501L133.598 47.7749C130.176 45.5425 126.098 44.2465 121.723 44.2465V46.2465ZM134.051 48.655C134.712 33.1226 147.364 20.7534 162.853 20.7534V18.7534C146.267 18.7534 132.758 31.9924 132.053 48.57L134.051 48.655ZM162.853 20.7534C177.258 20.7534 189.211 31.4534 191.343 45.4479L193.32 45.1466C191.044 30.2073 178.279 18.7534 162.853 18.7534V20.7534ZM192.401 46.2948C192.861 46.2628 193.325 46.2464 193.793 46.2464V44.2464C193.278 44.2464 192.768 44.2644 192.262 44.2997L192.401 46.2948ZM193.793 46.2464C204.792 46.2464 213.729 55.2709 213.729 66.4278H215.729C215.729 54.1884 205.919 44.2464 193.793 44.2464V46.2464ZM213.729 66.4278C213.729 77.5847 204.792 86.6091 193.793 86.6091V88.6091C205.919 88.6091 215.729 78.6672 215.729 66.4278H213.729ZM193.793 86.6091C193.775 86.6091 193.757 86.6091 193.739 86.609L193.734 88.609C193.753 88.6091 193.773 88.6091 193.793 88.6091V86.6091ZM121.799 88.609H193.736V86.609H121.799V88.609Z" fill="black" mask="url(#path-15-inside-2_141_84)"/>
<mask id="path-17-inside-3_141_84" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6744 111.413C7.46537 111.413 0 103.861 0 94.5436C0 85.2267 7.46537 77.6739 16.6744 77.6739C20.1066 77.6739 23.2966 78.723 25.9482 80.5216C26.3212 67.4972 36.8742 57.0547 49.8379 57.0547C61.8559 57.0547 71.8021 66.0291 73.4865 77.7153C73.8755 77.6878 74.2682 77.6739 74.664 77.6739C83.873 77.6739 91.3384 85.2267 91.3384 94.5436C91.3384 103.861 83.873 111.413 74.664 111.413C74.6022 111.413 74.5405 111.413 74.4789 111.412V111.601H16.6743L16.6744 111.413Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6744 111.413C7.46537 111.413 0 103.861 0 94.5436C0 85.2267 7.46537 77.6739 16.6744 77.6739C20.1066 77.6739 23.2966 78.723 25.9482 80.5216C26.3212 67.4972 36.8742 57.0547 49.8379 57.0547C61.8559 57.0547 71.8021 66.0291 73.4865 77.7153C73.8755 77.6878 74.2682 77.6739 74.664 77.6739C83.873 77.6739 91.3384 85.2267 91.3384 94.5436C91.3384 103.861 83.873 111.413 74.664 111.413C74.6022 111.413 74.5405 111.413 74.4789 111.412V111.601H16.6743L16.6744 111.413Z" fill="white"/>
<path d="M16.6744 111.413L17.6744 111.414L17.6745 110.413H16.6744V111.413ZM25.9482 80.5216L25.3868 81.3492L26.8956 82.3726L26.9478 80.5502L25.9482 80.5216ZM73.4865 77.7153L72.4967 77.8579L72.6294 78.7783L73.5569 78.7128L73.4865 77.7153ZM74.4789 111.412L74.4898 110.412L73.4789 110.401V111.412H74.4789ZM74.4789 111.601V112.601H75.4789V111.601H74.4789ZM16.6743 111.601L15.6743 111.601L15.6742 112.601H16.6743V111.601ZM16.6744 110.413C8.02861 110.413 1 103.319 1 94.5436H-1C-1 104.402 6.90213 112.413 16.6744 112.413V110.413ZM1 94.5436C1 85.768 8.02861 78.6739 16.6744 78.6739V76.6739C6.90213 76.6739 -1 84.6854 -1 94.5436H1ZM16.6744 78.6739C19.8995 78.6739 22.895 79.659 25.3868 81.3492L26.5095 79.694C23.6981 77.787 20.3137 76.6739 16.6744 76.6739V78.6739ZM26.9478 80.5502C27.3056 68.0546 37.4274 58.0547 49.8379 58.0547V56.0547C36.321 56.0547 25.3367 66.9398 24.9486 80.493L26.9478 80.5502ZM49.8379 58.0547C61.3429 58.0547 70.8809 66.648 72.4967 77.8579L74.4763 77.5726C72.7232 65.4102 62.3689 56.0547 49.8379 56.0547V58.0547ZM73.5569 78.7128C73.9225 78.687 74.2917 78.6739 74.664 78.6739V76.6739C74.2446 76.6739 73.8285 76.6886 73.4161 76.7178L73.5569 78.7128ZM74.664 78.6739C83.3098 78.6739 90.3384 85.768 90.3384 94.5436H92.3384C92.3384 84.6854 84.4363 76.6739 74.664 76.6739V78.6739ZM90.3384 94.5436C90.3384 103.319 83.3098 110.413 74.664 110.413V112.413C84.4363 112.413 92.3384 104.402 92.3384 94.5436H90.3384ZM74.664 110.413C74.606 110.413 74.548 110.413 74.4898 110.412L74.468 112.412C74.5331 112.413 74.5985 112.413 74.664 112.413V110.413ZM75.4789 111.601V111.412H73.4789V111.601H75.4789ZM16.6743 112.601H74.4789V110.601H16.6743V112.601ZM15.6744 111.413L15.6743 111.601L17.6743 111.602L17.6744 111.414L15.6744 111.413Z" fill="black" mask="url(#path-17-inside-3_141_84)"/>

  </svg>
);
export default SVG;
