import React from "react";
import Waterfall from "../static/img/waterfall";

export default function Hero() {
  return (
    <div className="banner2 has-background-success">
      <div className="banner">
        <section className="hero">
          <div className="hero-body">
            <div className="container  has-text-right">
              <div className="heading-text">
                <p>
                  <span className="white">What, </span>
                  <span className="has-text-link">when, </span>
                </p>
                <p>
                  <span className="white">where?</span>
                </p>
              </div>
              <p className="body-text white">
                <b>To be announced -</b> Reykjavik, Iceland
              </p>
            </div>
          </div>
        </section>
        <Waterfall />
      </div>
    </div>
  );
}
