import React from "react";
const SVG = () => (
  <svg
    fill="none"
    id="galleryArrow"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" fill="#566B76" />
    <path
      d="M35.519 44.6625L21.589 30.8158C21.3614 30.5896 21.25 30.2942 21.25 29.9988C21.25 29.7058 21.3614 29.4104 21.589 29.1842L35.519 15.3375C35.9718 14.8875 36.7077 14.8875 37.1605 15.3375C37.6132 15.7875 37.6132 16.5191 37.1605 16.9691L24.0523 29.9988L37.1605 43.0309C37.6132 43.4809 37.6132 44.2125 37.1605 44.6625C36.7077 45.1125 35.9718 45.1125 35.519 44.6625Z"
      fill="white"
    />
  </svg>
);

export default SVG;
