import React, { useState, useEffect } from "react";
import * as Data from "../../auth/Data";
import Card from "../Elements/Card";
import ArrowLink from "../Elements/ArrowLink";

export default function Speakers() {
  const [speakers, setSpeakers] = useState([]);
  let i = 0;
  useEffect(() => {
    async function fetch() {
      setSpeakers(await Data.getSpeakers());
    }
    fetch();
  }, []);
  /*<div className="body-text body-text-with-primary  type-3 spacesix width-6">
            <div className="is-hidden-mobile is-hidden-touch">
              <ArrowLink
                url="/speakers"
                text="View all speakers"
                color={true}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            {window.innerWidth >= 600
              ? speakers.slice(0, 6).map((speaker) => {
                  return (
                    <Card
                      key={i++}
                      path="/speakers"
                      title={speaker[0]}
                      type={speaker[3]}
                      img={speaker[2]}
                    />
                  );
                })
              : speakers.slice(0, 6).map((speaker) => {
                  return (
                    <Card
                      key={speaker[0].length}
                      path="/speakers"
                      title={speaker[0]}
                      type={speaker[3]}
                      img={speaker[2]}
                    />
                  );
                })}
          </div>
          <div className="is-hidden padding-top-10">
            <ArrowLink url="/speakers" text="See more" />
          </div>
*/
  return (
    <div>
      <section className="has-background-success speaker-section">
        <div className="container">
          <div className="heading-text has-text-link width-6 spacesix type-3 ">
            <p>Featured Speakers</p>
          </div>
          <div className="body-text white spacesix type-1 width-65 ">
            <p>To be announced </p>
          </div>
        </div>
      </section>
    </div>
  );
}
