import React, { useState, useEffect } from "react";
import Travel from "../Elements/Travel";
import * as Data from "../../auth/Data";

export default function Info() {
  const [travels, setTravels] = useState([]);

  useEffect(() => {
    async function fetch() {
      setTravels(await Data.getTravel());
    }
    fetch();
  }, []);
  return (
    <div className="has-background-primary page-1">
      <div className="">
        <div className="container black info">
          <div className="heading-text type-3 body-text-with-primary width-5 spacesix">
            <p>Practical Travel Info</p>
          </div>
          {travels.map((travel) => (
            <Travel title={travel[0]} bodytext={travel[1]} url={travel[2]} />
          ))}
        </div>
      </div>
    </div>
  );
}
