import React, { useState, useEffect } from "react";
import Harpa from "../static/img/harpa.js";

export default function Attend({ content }) {
  return (
    <div className="has-background-primary attend">
      <section className="container">
        <div className=" ">
          <div className="heading-text heading-text-wtih-pimary type-3 spacethree width-5">
            <p>{content[0][0]}</p>
          </div>
          <div className="body-text body-text-with-primary spacesix type-1 width-65 ">
            <p>{content[0][1]}</p>
          </div>
          <div className="btn">
            <a href="info@meniga.com" target="_blank">
              <button className="button is-danger">Get in touch</button>
            </a>
          </div>
        </div>
      </section>
      <Harpa></Harpa>
    </div>
  );
}
