import React from "react";

const SVG = () => (
  <svg
    viewBox="0 0 700 398"
    fill="none"
    id="iceland"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M269.645 116.849L154.004 1.20711V116.849H269.645Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M17.8335 116.068H178.001V107.18C178.001 68.7632 142.215 37.5166 97.9172 37.5166C53.6195 37.5166 17.8335 68.7632 17.8335 107.18V116.068Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M15.7343 114.682L15.7633 114.675L15.793 114.671L176.91 94.3228C176.91 94.3228 176.91 94.3228 176.911 94.3227C178.16 94.1611 179.048 93.011 178.886 91.7616C178.725 90.5116 177.575 89.6238 176.325 89.7851L176.324 89.7853L15.2069 110.134C15.2067 110.134 15.2064 110.134 15.2062 110.134C13.9574 110.295 13.0703 111.444 13.2301 112.693C13.3935 113.845 14.3861 114.692 15.5116 114.692C15.6538 114.692 15.7083 114.689 15.7343 114.682Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M19.9715 94.1979C19.7297 94.2781 19.4648 94.3188 19.223 94.3188C18.2708 94.3188 17.3878 93.732 17.0669 92.7905L19.9715 94.1979ZM19.9715 94.1979C19.9724 94.1976 19.9733 94.1973 19.9742 94.197M19.9715 94.1979L19.9742 94.197M19.9742 94.197L143.851 51.242C143.851 51.2417 143.852 51.2415 143.852 51.2413C145.044 50.8163 145.667 49.5269 145.266 48.3382C144.84 47.1476 143.55 46.5248 142.362 46.9268C142.361 46.927 142.361 46.9272 142.36 46.9274L18.4814 89.8832C18.4807 89.8834 18.4801 89.8836 18.4795 89.8838C17.2874 90.3092 16.6638 91.6003 17.0667 92.7899L19.9742 94.197Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M292.422 59.1815V37.105H259.704V59.1815H292.422Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M259.168 111.504V37.1055H256.703V111.504H259.168Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M274.287 59.1817V37.0068H270.397V59.1817H274.287Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M292.497 50.0039V44.728H259.704V50.0039H292.497Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M259.277 47.6568H292.441C292.464 47.6568 292.475 47.6493 292.48 47.6434C292.487 47.6372 292.497 47.6225 292.497 47.5916C292.497 47.5607 292.487 47.546 292.48 47.5398C292.475 47.5339 292.464 47.5264 292.441 47.5264H259.277C259.254 47.5264 259.244 47.5339 259.238 47.5398C259.232 47.546 259.221 47.5607 259.221 47.5916C259.221 47.6225 259.232 47.6372 259.238 47.6434C259.244 47.6493 259.254 47.6568 259.277 47.6568Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M272.28 59.8703C272.309 59.8703 272.323 59.8608 272.329 59.8539C272.336 59.8471 272.346 59.8333 272.346 59.8051V36.6302C272.346 36.602 272.336 36.5881 272.329 36.5813C272.323 36.5745 272.309 36.5649 272.28 36.5649C272.252 36.5649 272.238 36.5745 272.232 36.5813C272.225 36.5881 272.215 36.6019 272.215 36.6302V59.8051C272.215 59.8333 272.225 59.8471 272.232 59.8539C272.238 59.8608 272.252 59.8703 272.28 59.8703Z"
      fill="#F4F4F0"
      stroke="black"
    />
    <path
      d="M144.5 191.5C77.2027 191.5 20.3567 157.495 0.747348 110.5H288.252C268.642 157.431 211.796 191.5 144.5 191.5Z"
      fill="#F4F4F0"
      stroke="#88BC76"
    />
  </svg>
);
export default SVG;
