import React, { useState, useEffect } from "react";
import Boat from "../static/img/boat";
import * as Data from "../../auth/Data";

export default function Sponsors() {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    async function fetch() {
      setLogos(await Data.getLogos());
    }
    fetch();
  }, []);
  /*
   {logos.map((logo, index) => (
              <div key={Math.floor(Math.random() * 1000)} className="mulitpsons is-vcentered is-centered is-multiline map-frame is-mobile  columns">
                {logo.map((img, index) => (
                  <div key={Math.random() * 123}>
                    <div key={index + img.length} className="column spacethree is-hidden-mobile is-hidden-touch is-one-quarter">
                      <img alt="img" src={img}></img>
                    </div>
                    <div key={img.length - index} className="column is-hidden-desktop is-5">
                      <img alt="img" src={img}></img>
                    </div>
                  </div>
                ))}
              </div>
            ))}*/
  /*<div className="scroller spacesix ">
            {logos.map((logo, index) => (
              <div
                key={Math.floor(Math.random() * 1000)}
                className="mulitpsons is-vcentered is-centered is-multiline map-frame is-mobile  columns"
              >
                {logo.map((img, index) => (
                  <div
                    key={index + img.length * Math.random()}
                    className="column spacethree is-one-quarter"
                  >
                    <div
                      key={index + img.length * Math.random()}
                      className="is-hidden-mobile "
                    >
                      <img alt="img" src={img}></img>
                    </div>
                    <div
                      key={img.length - index * Math.random()}
                      className="is-hidden-desktop"
                    >
                      <img alt="img" src={img}></img>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>*/

  return (
    <div className="has-background-primary sponsors">
      <section className="">
        <div className="container">
          <div className="heading-text heading-text-wtih-pimary type-3  spacesix">
            <p>Our Partners</p>
          </div>
          <div className="body-text width-6 body-text-with-primary type-4 spacesix">
            <p>To be announced</p>
          </div>
          <div className="involved is-shadowless">
            <div className="heading-text type-6 heading-text-wtih-pimary  width-5">
              <p>We would be pleased to explore your involvement with Fin42.</p>
            </div>
            <br />
            <div className="body-text width-6 body-text-with-primary type-4">
              <p>
                Our sponsorship team has created various ways to enable you to
                get involved, find out more and be sure to contact us if you
                have bespoke ideas on how you can have an impact.
              </p>
            </div>
            <div className="buttons takki">
              <a
                className="button  is-large is-danger"
                href="mailto:info@meniga.com"
              >
                Become a Sponsor
              </a>
            </div>
          </div>
        </div>
      </section>
      <Boat />
    </div>
  );
}
