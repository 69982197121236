import React, { useState } from "react";

export default function Card({
  company,
  img,
  title,
  typeofspeaker,
  type,
  timeslot,
  companylogo,
  even,
}) {
  const [active, setActive] = useState(false);

  return (
    <div
      id={title.split(" ")[0]}
      className={
        even ? "columns card-large" : "columns card-large has-background-white"
      }
    >
      <div className="column is-one-third">
        <div className="">
          <div className="is-hidden-desktop">
            <p className="heading-text type-5 font-1">{title}</p>
            <p className="body-text type-2 spacethree">{company}</p>
          </div>
          <figure class="image is-fullwidth">
            <img alt="Profile" loading="lazy" src={img}></img>
          </figure>
        </div>
        <div className="map-frame companylogo">
          <img
            alt="Profile"
            loading="lazy"
            className="blackwhite"
            src={companylogo}
          ></img>
        </div>
      </div>
      <div className="column is-paddingless is-two-thirds">
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <div className="is-hidden-mobile">
                <p className="heading-text type-5 font-1">{title}</p>
                <p className="body-text type-2 spacethree">{company}</p>
              </div>
              {type.map((bio, index) => (
                <div>
                  {(index < 1 || active) && (
                    <>
                      <p className="body-text type-4">{bio.text}</p>
                      <br />
                    </>
                  )}
                </div>
              ))}
              {type.length > 1 && (
                <div className="readmore">
                  <a
                    className="font-1 body-text-color"
                    onClick={() => setActive(!active)}
                  >
                    {!active ? "Read More" : "Close"}
                  </a>
                </div>
              )}
              <div className="divider has-background-warning	"></div>
              <p className="body-text type-2 body-text-color">
                {typeofspeaker}
              </p>
              <p className="body-text type-2 body-text-color">{timeslot}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
