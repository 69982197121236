import React, { useState } from "react";
import Logo from "../static/img/logo";
import { createBrowserHistory } from "history";

function Nav() {
  let [active, setActive] = useState(false);
  const history = createBrowserHistory();

  return (
    <nav className="navbar has-background-primary is-fixed-top">
      <div className="container">
        <div className="navbar-brand">
          <a className="" href="/">
            <Logo className="img" />
          </a>
          <a
            alt="menu"
            role="button"
            className={
              active
                ? "navbar-burger burger heading-text is-pulled-right is-active type-7"
                : "navbar-burger heading-text burger type-7"
            }
            aria-label="menu"
            aria-expanded="false"
            data-target="Links"
            onClick={() => setActive(!active)}
          >
            {!active ? (
              <>
                menu
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="3" y="8" width="18" height="2" fill="#566B76" />
                  <rect x="3" y="14" width="10" height="2" fill="#566B76" />
                </svg>
              </>
            ) : (
              <>
                close
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 5L5 19" stroke="#566B76" stroke-width="2" />
                  <path d="M5 5L19 19" stroke="#566B76" stroke-width="2" />
                </svg>
              </>
            )}
          </a>
        </div>
        <div
          id="navMenu"
          className={
            active
              ? "navbar-menu has-text-centered has-background-primary navbar is-active"
              : "navbar-menu"
          }
        >
          <div className="navbar-end">
            <a href="/agenda" className="navbar-item">
              <span className="navbartext">Agenda</span>
            </a>
            <a href="/speakers" className="navbar-item">
              <span className="navbartext">Speakers</span>
            </a>
            <a href="/location" className="navbar-item">
              <span className="navbartext">Location</span>
            </a>
            <a className="navbar-item" href="/events">
              <span className="navbartext">Past Events</span>
            </a>
            <div className="buttons">
              <a href="info@meniga.com" target="_blank">
                <button className="button navbartext is-large is-danger">
                  Get in touch
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
