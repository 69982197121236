import React from "react";

const SVG = () => (
  <svg
    id="linkedin"
    fill="none"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="29.5" stroke="#566B76" />

    <path
      d="M23.8162 40H19.0684V26.8894H23.8162V40ZM33.6732 31.1624C32.5204 31.1624 31.5826 32.0659 31.5826 33.1765V40H26.5906V26.8894H31.5826V28.3859C31.5826 28.3859 33.2531 26.88 35.754 26.88C38.8508 26.88 41 29.0918 41 33.2894V40H35.7638V33.1765C35.7638 32.0659 34.8259 31.1624 33.6732 31.1624ZM23.8845 22.3529C23.8845 23.6518 22.7904 24.7059 21.4423 24.7059C20.0941 24.7059 19 23.6518 19 22.3529C19 21.0541 20.0941 20 21.4423 20C22.7904 20 23.8845 21.0541 23.8845 22.3529Z"
      fill="#566B76"
    />
  </svg>
);

export default SVG;
