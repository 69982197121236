import React from "react";

const SVG = (fill) => (
  <svg fill="none" id="arrow-left" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9798 11.8117C22.978 11.8016 22.9725 11.7924 22.9707 11.7814C22.9587 11.731 22.9441 11.6824 22.9239 11.6366C22.9166 11.6183 22.9056 11.6036 22.8964 11.5862C22.8781 11.5495 22.8579 11.5119 22.835 11.4771C22.8203 11.4569 22.8038 11.4404 22.7882 11.4212C22.7653 11.3928 22.7415 11.3644 22.7158 11.3387C22.7103 11.335 22.7067 11.3286 22.7021 11.324L11.7021 1.2407C11.329 0.89878 10.7488 0.924446 10.4068 1.29661C10.0649 1.67061 10.0906 2.24995 10.4637 2.59186L19.7257 11.0829H1.91667C1.40975 11.0829 1 11.4936 1 11.9996C1 12.5065 1.40975 12.9163 1.91667 12.9163H19.7257L10.4637 21.4074C10.0906 21.7502 10.0649 22.3295 10.4068 22.7026C10.5883 22.9006 10.8349 22.9996 11.0833 22.9996C11.3052 22.9996 11.5261 22.9199 11.7021 22.7585L22.7021 12.6752C22.7067 12.6706 22.7103 12.666 22.7158 12.6605C22.7424 12.6349 22.7653 12.6064 22.7892 12.578C22.8048 12.5597 22.8203 12.5423 22.835 12.5221C22.8579 12.4873 22.8781 12.4506 22.8964 12.413C22.9056 12.3965 22.9166 12.3809 22.9239 12.3644C22.9441 12.3177 22.9587 12.2673 22.9707 12.2169C22.9725 12.2077 22.978 12.1985 22.9798 12.1875C22.9927 12.127 23 12.0638 23 11.9996C23 11.9354 22.9927 11.8722 22.9798 11.8117Z"
      className="arr"
    />
  </svg>
);
export default SVG;
