import React from "react";

const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" id="star">
    <path
      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
      fill="white"
    />
    <path
      d="M8.99994 0V12.4377L3.70987 16.2812L5.7305 10.0623L0.44043 6.21885H6.97931L8.99994 0Z"
      fill="#FEB734"
    />
  </svg>
);
export default SVG;
