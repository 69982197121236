import React from "react";

const SVG = () => (
  <svg
    id="facebook"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="29.5" stroke="#566B76" />

    <path
      d="M36.2675 25.4826H31.9995V23.5776C31.9995 22.6816 32.5935 22.4726 33.0115 22.4726H35.9995V18.5306L31.6705 18.5176C27.7445 18.5176 26.9995 21.4566 26.9995 23.3386V25.4826H23.9995V29.4826H26.9995V41.4826H31.9995V29.4826H35.8505L36.2675 25.4826Z"
      fill="#566B76"
    />
  </svg>
);
export default SVG;
