import React, { useState, useEffect } from "react";
import img from "../static/img/image002.png";
import img2 from "../static/img/image.png";
import * as Data from "../../auth/Data";

export default function Content() {
  return (
    <div className="has-background-success waterfall">
      <>
        <section className="page-1 ">
          <div className=" container white">
            <div className="body-text type-1 columns spacesix">
              <div className="column is-half">
                <p>To be announced</p>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}
