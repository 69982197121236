import React from "react";

export default function Hotel({ name, street, price1, price2, img, link }) {
  const single = (
    <svg
      id="single"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="#4B5E68"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" fill="#4B5E68" />
      <path
        d="M39.8667 45H20.1333C18.96 45 18 44.0526 18 42.8947V17.1053C18 15.9474 18.96 15 20.1333 15H39.8667C41.04 15 42 15.9474 42 17.1053V42.8947C42 44.0526 41.04 45 39.8667 45Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.8379 21.2632C24.8379 20.1579 25.7659 19.2632 26.9072 19.2632H33.1046C34.2459 19.2632 35.1739 20.1579 35.1739 21.2632V23.2632C35.1739 24.3684 34.2459 25.2632 33.1046 25.2632H26.9072C25.7659 25.2632 24.8379 24.3684 24.8379 23.2632V21.2632Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6387 25.2632H41.3587"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6387 31.2632H41.3587"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const breaks = (
    <svg id="break" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" fill="#4B5E68" />
      <path
        d="M37.3789 17.9998H38.6789C40.7089 17.8998 42.4389 19.4098 42.5689 21.3898C42.4389 23.3698 40.6989 24.8798 38.6789 24.7798H37.3789"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.8895 32.6801C33.6995 32.1901 33.2195 31.8601 32.6795 31.8601H26.7195C26.1795 31.8601 25.6995 32.1901 25.5095 32.6801L22.3195 41.0101C22.0995 41.5901 22.3395 42.2301 22.8795 42.5401C27.0295 45.1501 32.3595 45.1501 36.5195 42.5401C37.0595 42.2301 37.2895 41.5801 37.0795 41.0101L33.8895 32.6801Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4292 32.9099L21.4792 31.4099C21.0992 31.2699 20.6692 31.3699 20.3892 31.6699L15.7892 36.7099C15.4392 37.0399 15.4192 37.5899 15.7492 37.9499C17.5892 39.5899 19.9392 40.5799 22.4192 40.7699"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8796 36.6201C13.4996 34.4101 12.4996 31.1401 13.2396 28.0201C13.3196 27.6201 13.6296 27.3001 14.0196 27.1901C14.4196 27.0501 14.8696 27.1601 15.1396 27.4801C16.1096 29.5601 17.8496 31.2001 20.0096 32.0801"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.0801 32.9099L38.0301 31.4099C38.4101 31.2699 38.8401 31.3699 39.1201 31.6699L43.7201 36.7099C44.0701 37.0399 44.0901 37.5899 43.7601 37.9499C41.9201 39.5899 39.5701 40.5799 37.0901 40.7699"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.64 36.6201C46.02 34.4101 47.02 31.1401 46.27 28.0201C46.19 27.6201 45.89 27.3001 45.49 27.1901C45.09 27.0501 44.64 27.1601 44.37 27.4801C43.4 29.5601 41.66 31.2001 39.5 32.0801"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.3809 28.06V18.19C37.3809 16.98 36.3809 16 35.1409 16H23.9509C22.7109 16 21.7109 16.98 21.7109 18.19V27.94"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const double = (
    <svg id="double" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" fill="#4B5E68" />
      <path
        d="M42.3333 45H17.6667C16.2 45 15 44.0526 15 42.8947V17.1053C15 15.9474 16.2 15 17.6667 15H42.3333C43.8 15 45 15.9474 45 17.1053V42.8947C45 44.0526 43.8 45 42.3333 45Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 21C19 19.8947 19.808 19 20.8019 19H26.1981C27.192 19 28 19.8947 28 21V23C28 24.1053 27.192 25 26.1981 25H20.8019C19.808 25 19 24.1053 19 23V21Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32 21C32 19.8947 32.808 19 33.8019 19H39.1981C40.192 19 41 19.8947 41 21V23C41 24.1053 40.192 25 39.1981 25H33.8019C32.808 25 32 24.1053 32 23V21Z"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 25L45 25"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 31L45 31"
        stroke="#88BC76"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  return (
    <div className="hotel column spacesix">
      <div className="heading-text type-1 has-text-link">
        <p>{name}</p>
      </div>
      <div className="body-text type-3 white paddingupdown">
        <p>{street}</p>
      </div>
      <div className="columns is-variable is-8">
        <div className="column  is-half">
          <img alt="Profile" className="" src={img}></img>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="button  is-danger"
          >
            Book Now
          </a>
        </div>
        <div className="column  is-half">
          <div className="body-text  white type-4">
            <p className="is-pulled-left spacethree">
              {single}
              <span>
                <b>{price1} ISK&nbsp;</b>per room/night (single use)
              </span>
            </p>

            <p className="is-pulled-left spacethree">
              {double}
              <span>
                <b>{price2} ISK&nbsp;</b>per room/night (double use)
              </span>
            </p>
            <p className="is-pulled-left spacethree">
              {breaks}
              <b>Breakfast included</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
