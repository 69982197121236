import React from "react";
import Hero from "./Hero";
import Content from "./Content";
export default function index() {
  return (
    <div>
      <Hero />
      <div className="fix "></div>

      <Content />
    </div>
  );
}
