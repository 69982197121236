import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Youtube from "react-youtube";
import * as Data from "../../auth/Data";
import GalleryArrow from "../static/img/GalleryArrow";
import GalleryArrowMinus from "../static/img/GalleryArrowMinus";
import FsLightbox from "fslightbox-react";

export default function Content() {
  const [ytvideos, setYTVideos] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [fullgallery, setFullGallery] = useState([]);
  const [num, setNum] = useState(0);
  const [tempnum, setTempNum] = useState(0);
  const [featured, setFeatured] = useState("");
  const [toggler, setToggler] = useState(false);

  const url = "https://www.youtube.com/watch?v=g6ZUGOVZhBo&t=1s";
  useEffect(() => {
    async function fetch() {
      setYTVideos(await Data.getYTLinks());
      const temp = await Data.getGallery();
      setFullGallery(temp);
      setFeatured(temp[0]);
      setTempNum(temp.length - 4);
    }
    fetch();
  }, [gallery]);
  const videoOptions = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 1,
      cc_load_policy: 0,
      mute: 1,
    },
  };

  return (
    <div className="has-background-success events">
      <section className="page-1">
        <div className="container">
          <div className="body-text type-1 width-6 white spacesix">
            <p>
              In 2018 we hosted the first formal Fin42 event in the iconic Harpa
              Concert hall in Reykjavik Iceland. The event attracted 220 people
              from 20 countries representing more than 80 organizations.
            </p>
          </div>
          <div className="video-1">
            <ReactPlayer url={url} muted height="551px" width="100%" />
          </div>
          <div className="gallery pacesix">
            <div className="heading-text width-6 spacethree type-1 has-text-link">
              <div className="iconsarrows is-hidden-mobile">
                <a
                  onClick={() => {
                    if (num >= 1) {
                      setNum(num - 1);
                      setFeatured(fullgallery[num - 1]);
                    }
                  }}
                >
                  <GalleryArrowMinus />
                </a>
                <a
                  onClick={() => {
                    if (num + 5 >= fullgallery.length) {
                      setTempNum(tempnum + 1);
                      if (tempnum >= fullgallery.length) {
                        setFeatured(fullgallery[0]);
                        setNum(0);
                      } else {
                        setFeatured(fullgallery[tempnum]);
                      }
                    } else {
                      setNum(num + 1);
                      setFeatured(fullgallery[num + 1]);
                    }
                  }}
                >
                  <GalleryArrow />
                </a>
              </div>

              <p>Check some pictures from our last event</p>
            </div>
            <FsLightbox
              toggler={toggler}
              sources={[
                fullgallery[0],
                fullgallery[1],
                fullgallery[2],
                fullgallery[3],
                fullgallery[4],
                fullgallery[5],
                fullgallery[6],
                fullgallery[7],
                fullgallery[8],
              ]}
              svg={{
                toolbarButtons: {
                  thumbs: {
                    viewBox: "0 0 32 32",
                    width: "40px",
                    height: "40px",
                    d: "M 6 3 A 1 1 0 0 0 5 4 A 1 1 0 0 0 5 4.0039062",
                    title: "Thumbnails",
                  },
                  zoomIn: {
                    width: "40px",
                  },
                  zoomOut: {
                    height: "30px",
                  },
                  close: {
                    height: "32px",
                  },
                  fullscreen: {
                    enter: {
                      d: "M121",
                    },
                    exit: {
                      title: "Fullscreen - exit",
                    },
                  },
                  slideshow: {
                    start: {
                      d: "M123",
                    },
                    pause: {
                      viewBox: "0 0 31 31",
                    },
                  },
                },
                slideButtons: {
                  previous: {
                    width: "100px",
                    height: "100px",
                  },
                  next: {
                    title: "Next",
                  },
                },
              }}
            />

            <div className="images">
              <div className="columns is-hidden-mobile">
                {fullgallery.slice(num, num + 5).map((image) => (
                  <div className="column is-one-fifth">
                    <figure className="image is-16by9">
                      <img src={image} onClick={() => setFeatured(image)} />
                    </figure>
                  </div>
                ))}
              </div>
              <figure className="image is-5by3">
                <img src={featured} onClick={() => setToggler(!toggler)} />
              </figure>
              <div className="is-hidden-desktop ">
                <a
                  onClick={() => {
                    if (num >= 1) {
                      setNum(num - 1);
                      setFeatured(fullgallery[num - 1]);
                    }
                  }}
                >
                  <GalleryArrowMinus />
                </a>

                <a
                  className="is-pulled-right"
                  onClick={() => {
                    if (num + 5 >= fullgallery.length) {
                      setTempNum(tempnum + 1);
                      if (tempnum >= fullgallery.length) {
                        setFeatured(fullgallery[0]);
                        setNum(0);
                      } else {
                        setFeatured(fullgallery[tempnum]);
                      }
                    } else {
                      setNum(num + 1);
                      setFeatured(fullgallery[num + 1]);
                    }
                  }}
                >
                  <GalleryArrow />
                </a>
              </div>
            </div>
          </div>
          <div className="ytvideos">
            <div className="heading-text width-6 spacethree type-1  has-text-link">
              Watch some of our past keynotes
            </div>
            <div className="columns is-multiline">
              {ytvideos.map((post) => (
                <div className="column is-one-third">
                  <p className="body-text type-2 white">{post[1]}</p>
                  <Youtube
                    videoId={post[2]}
                    className="video"
                    opts={videoOptions}
                  />
                  <p className="heading-text type-2 has-text-link">{post[0]}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
